import { AspectRatioConfig } from "../styles/AspectRatio.config";
import {
  AspectRatioLayout,
  AspectRatioPosition,
  AspectRatioSize,
  AspectRatioVariant,
} from "../types/AspectRatio.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";

export interface AspectRatioData {}

export interface AspectRatioOptions {
  /**
   * Variants for `AspectRatio`. You can extend the variant.
   */
  variant?: AspectRatioVariant;
  /**
   * The size applies ratio of the box
   */
  size?: AspectRatioSize;
  /**
   * Stretch both width and height to the dimensions of the parent element,
   * usually paired with object-fit.
   */
  layout?: AspectRatioLayout;
  /**
   * Position the media in different locations within the cropping area. Required 'isFill' to be false
   */
  position?: AspectRatioPosition;
  /**
   * Control the min-height of the content
   */
  minHeight?: number;
}
export interface AspectRatioProps
  extends Omit<HTMLProps<"div">, keyof AspectRatioData>,
    AspectRatioOptions,
    AspectRatioData {}

type AspectRatioParts = ForwardRefComponent<"div", AspectRatioProps>;

/**
 * AspectRatio component is used to embed responsive videos and maps, etc. It uses a very common padding hack to achieve this
 */
export const AspectRatio: AspectRatioParts = forwardRef<
  AspectRatioProps,
  "div"
>((props, ref) => {
  const {
    variant = AspectRatioVariant.Default,
    size = AspectRatioSize.SixteenDivideByNine,
    layout = AspectRatioLayout.Contain,
    position = AspectRatioPosition.CenterCenter,
    minHeight,
    className,
    children,
    ...rest
  } = props;

  const { styles } = useCompConfig(AspectRatioConfig, {
    variant,
    css: {
      size,
      layout,
      position,
    },
  });

  let rootStyle: JSX.IntrinsicElements["div"]["style"] | undefined;
  if (minHeight) {
    rootStyle = {
      minHeight: `${minHeight}px`,
    };
  }

  return (
    <div
      style={rootStyle}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <div className={styles.Content}>{children}</div>
    </div>
  );
});

AspectRatio.displayName = `AspectRatio`;
