import * as React from "react";
import { LazyHydrate } from ".";
import { HydrationMode } from "./types";

export const withLazyHydration = (mode: HydrationMode, Component: any) => {
  const LazyHydratedComponent = (props: Record<any, any>) => (
    <LazyHydrate mode={mode}>
      <Component {...props} />
    </LazyHydrate>
  );

  return LazyHydratedComponent;
};
