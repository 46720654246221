export enum CarouselParts {
  Root = "Root",
  Wrapper = "Wrapper",
  Slides = "Slides",
  Slide = "Slide",
  DotButtons = "DotButtons",
  DotButton = "DotButton",
}

export enum CarouselVariant {
  Default = "default",
}
