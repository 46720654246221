import { AppFooterParts, AppFooterVariant } from "../types/AppFooter.constants";
import { TAppFooterConfigProps } from "../types/AppFooter.types";

export const AppFooterConfig: TAppFooterConfigProps = {
  parts: Object.values(AppFooterParts),
  css: {
    variants: {
      [AppFooterVariant.Default]: {
        Root: "pt-lg",
        Nav: "flex flex-col sm:flex-row sm:items-center sm:justify-between",
        Brand:
          "flex-shrink-0 self-center sinline-block w-[125px] text-inherit hover:text-accent transition-colors  mb-lg md:mb-0",
      },
    },
  },
};
