import React from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { VideoConfig } from "../styles/Video.config";
import { VideoPosition, VideoVariant } from "../types/Video.constants";
import { VideoProvider, useVideo, UseVideoProps } from "../utils/use-video";
import { AspectRatio, AspectRatioLayout, AspectRatioProps } from "layout";

export interface VideoData {
  src?: string;
  posterSrc?: string;
}

export interface VideoOptions {
  /**
   * Variants for `Video`. You can extend the variant.
   */
  variant?: VideoVariant;
  position?: VideoPosition;
  aspectRatio?: AspectRatioProps;
}
export interface VideoProps
  extends Omit<HTMLProps<"div">, keyof VideoData>,
    VideoOptions,
    VideoData,
    UseVideoProps {}

type VideoParts = ForwardRefComponent<"div", VideoProps>;

export const Video: VideoParts = forwardRef<VideoProps, "div">((props, ref) => {
  const {
    variant = VideoVariant.Default,
    className,
    children,
    src,
    posterSrc,
    aspectRatio,
    position = VideoPosition.Right,
    ...rest
  } = props;

  const { ...ctx } = useVideo(rest);
  const context = React.useMemo(() => ctx, [ctx]);

  const { styles } = useCompConfig(VideoConfig, { variant, css: { position } });

  return (
    <VideoProvider value={context}>
      <AspectRatio
        layout={AspectRatioLayout.Cover}
        className={cx(styles.Root, className)}
        ref={ref}
        {...aspectRatio}
      >
        {src && (
          <div className={styles.Wrapper}>
            <div className="w-full h-full">
              <video
                ref={ctx.videoRef}
                playsInline
                autoPlay={ctx.isInView}
                loop
                muted
                poster={posterSrc}
                src={src}
              >
                <track kind="captions" />
              </video>
            </div>
          </div>
        )}
        {/* <div className="backdrop-blur-lg bg-white/10 absolute top-0 left-0 w-full h-full" /> */}
      </AspectRatio>
    </VideoProvider>
  );
});

Video.displayName = `Video`;
