import { storyblokEditable } from "@hybrbase/storyblok";
import { uid } from "react-uid";
import { SbTile } from "./SbTile";
import { SbTextContainer } from "./SbTextContainer";
import { Grid } from "layout";
import { SbAspectRatioImage } from "../elements";

const Components = {
  tile: SbTile,
  text_container: SbTextContainer,
  aspect_ratio_image: SbAspectRatioImage,
};

export const SbColumn = ({ blok }) => {
  const { variant, size, components } = blok; // Component scheme
  return (
    <Grid.Item variant={variant} size={size} {...storyblokEditable(blok)}>
      {components?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Grid.Item>
  );
};
