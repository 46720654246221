import { AppHeaderConfig } from "../styles/AppHeader.config";
import { AppHeaderVariant } from "../types/AppHeader.constants";
import {
  AppHeaderProvider,
  useAppHeader,
  UseAppHeaderProps,
} from "../utils/use-app-header";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { Breakpoint, ThemeElement } from "@boilerplate/themes";
import React, { useCallback, useEffect } from "react";
import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  IconSize,
  Link,
  LinkProps,
} from "elements";
import { uid } from "react-uid";
import { Container, CssTransition, Tile, TileSpace } from "layout";
import { isBrowser, m } from "framer-motion";
import { useBreakpoint } from "hooks";

export interface AppHeaderData {
  links?: LinkProps[];
  ctaLink?: LinkProps;
  locales: string[];
  locale: string;
  asPath: string;
}

export interface AppHeaderOptions extends UseAppHeaderProps {
  isSticky?: boolean;
  theme?: ThemeElement;
  variant?: AppHeaderVariant;
}

export interface AppHeaderProps
  extends Omit<HTMLProps<"header">, keyof AppHeaderData>,
    AppHeaderOptions,
    AppHeaderData {}

type AppHeaderParts = ForwardRefComponent<"header", AppHeaderProps>;

export const AppHeader: AppHeaderParts = forwardRef<AppHeaderProps, "header">(
  (props, ref) => {
    const {
      variant = AppHeaderVariant.Default,
      theme = ThemeElement.Default,
      isSticky = true,
      className,
      links,
      ctaLink,
      locales,
      locale,
      asPath,
      ...rest
    } = props;
    const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
    const isTablet = useBreakpoint(Breakpoint.TabletLandScape);

    const { styles } = useCompConfig(AppHeaderConfig, { variant });

    const { ...ctx } = useAppHeader(rest);
    const context = React.useMemo(() => ctx, [ctx]);

    // set initial always to be false
    useEffect(() => {
      setIsMobileNavOpen(false);
    }, [asPath, isTablet, locale]);

    useEffect(() => {
      if (isBrowser) {
        document.body.classList.toggle("overflow-hidden", isMobileNavOpen);
      }
    }, [isMobileNavOpen]);

    const toggle = useCallback(() => {
      setIsMobileNavOpen((prev) => !prev);
    }, []);

    return (
      <AppHeaderProvider value={context}>
        <header
          data-theme-element={theme}
          className={cx(
            styles.Root,
            { fixed: isSticky, relative: !isSticky },
            className
          )}
          ref={ref}
          {...rest}
        >
          <Container className={styles.Container}>
            <nav
              className={styles.Nav}
              role="navigation"
              aria-labelledby="app-header-nav"
            >
              <span id="app-header-nav" className="sr-only">
                app-header navigation
              </span>

              <div className="flex-1">
                <Link title="home" href="/" className={styles.Brand}>
                  <span className="sr-only">home</span>
                  <Icon name={IconName.ONECYCLE} size={IconSize.Auto} />
                </Link>
              </div>
              <ul className="hidden md:flex md:-ml-lg flex-1 justify-center items-center space-x-md pl-0">
                {links?.map((item, index) => (
                  <li key={uid(index)}>
                    <Link
                      className={cx(
                        "text-sm text-primary hover:text-accent active:text-accent",
                        {
                          ["!text-accent pointer-events-none"]:
                            `/${item?.href}/` === asPath,
                        }
                      )}
                      href={item?.href}
                      title={item?.children}
                    >
                      {item?.children}
                    </Link>
                  </li>
                ))}
              </ul>

              <div className="flex-1">
                <ul className="flex items-center justify-end space-x-md">
                  {ctaLink?.children && (
                    <li className="hidden md:inline-block">
                      <Button.Link {...ctaLink} />
                    </li>
                  )}
                  {ctaLink?.children && !isTablet && (
                    <li className="block md:hidden">
                      <Button.Link
                        variant={ButtonVariant.Reset}
                        className="mr-xs text-primary sm:text-sm font-bold"
                        {...ctaLink}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </nav>

            {styles.MenuMobileBackdrop && (
              <button
                className={cx(styles.MenuMobileBackdrop, {
                  "pointer-events-auto opacity-100 right-0 top-0 w-full h-full":
                    isMobileNavOpen,
                  "opacity-0": !isMobileNavOpen,
                })}
                onClick={() => setIsMobileNavOpen(false)}
              />
            )}

            <m.div
              initial="hide"
              animate={isMobileNavOpen ? "show" : "hide"}
              variants={{
                show: {
                  y: "0%",
                },
                hide: {
                  y: "100%",
                },
              }}
              transition={{ duration: 0.9, ease: [0.16, 1, 0.3, 1] }}
              className={styles.MenuMobile}
              data-theme-element={ThemeElement.Invert}
            >
              <div className={styles.MenuMobileInner}>
                <Tile
                  className="mt-fluid-xxl flex flex-col justify-between"
                  space={TileSpace.Sm}
                >
                  <div className="flex flex-grow">
                    <CssTransition
                      delay={0.125}
                      className="flex flex-col space-y-md items-center self-center"
                      show={isMobileNavOpen}
                    >
                      {links?.map((item, index) => (
                        <Link
                          key={uid(index)}
                          className={cx(
                            "text-fluid-xxl uppercase font-primary font-bold text-white hover:text-accent active:text-accent",
                            {
                              ["!text-accent pointer-events-none"]:
                                `/${item?.href}/` === asPath,
                            }
                          )}
                          href={item?.href}
                          title={item?.children}
                        >
                          {item?.children}
                        </Link>
                      ))}

                      <div className="flex-1 mt-md mb-lg">
                        <ul className="flex space-x-md items-center">
                          <li className="">
                            {locales?.map((key, index) => {
                              const isLastIndex = index === locales.length - 1;

                              return (
                                <Link
                                  key={uid(index)}
                                  className={cx(
                                    "text-fluid-lg text-white hover:text-accent active:text-accent",
                                    { "mr-xs": !isLastIndex }
                                  )}
                                  href={asPath}
                                  locale={key}
                                >
                                  <span
                                    className={cx({
                                      "!text-accent pointer-events-none":
                                        locale === key,
                                    })}
                                  >
                                    {key.toUpperCase()}
                                  </span>
                                  {!isLastIndex && (
                                    <span
                                      className="text-contrast-low ml-xs"
                                      aria-hidden="true"
                                    >
                                      /
                                    </span>
                                  )}
                                </Link>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                      <Button.Link
                        className="text-primary w-full mt-lg text-center"
                        iconName={IconName.ArrowRight}
                        {...ctaLink}
                      />
                    </CssTransition>
                  </div>
                </Tile>
              </div>
            </m.div>

            <ul className="hidden md:flex ml-sm items-center justify-end space-x-md  border-1 border-solid rounded-full border-contrast-low px-xs bg-white">
              <li>
                {locales?.map((key, index) => {
                  return (
                    <Link
                      key={uid(index)}
                      className={cx(
                        "text-sm text-primary hover:text-accent active:text-accent",
                        {
                          "pointer-events-none": locale === key,
                        }
                      )}
                      href={asPath}
                      locale={key}
                    >
                      <span
                        className={cx(
                          " w-xl h-xl inline-flex justify-center items-center",
                          {
                            "!text-accent border-1 border-solid rounded-full border-contrast-low":
                              locale === key,
                          }
                        )}
                      >
                        {key.toUpperCase()}
                      </span>
                    </Link>
                  );
                })}
              </li>
            </ul>

            <Button
              variant={ButtonVariant.Reset}
              className={cx(
                "md:hidden ham-wrapper ham-stack flex-shrink-0 rounded-full border-1 border-solid border-contrast-low bg-white h-[50px] md:h-[70px] w-[50px]  md:w-[70px] ml-sm z-overlay",
                {
                  ["ham-active"]: isMobileNavOpen,
                }
              )}
              onClick={toggle}
              ariaLabel="mobile-nav"
              aria-label="Toggle Menu"
              aria-expanded={isMobileNavOpen}
            >
              <div className="ham-inner">
                <span className="ham-bar" />
                <span className="ham-bar" />
                <span className="ham-bar" />
              </div>
            </Button>
          </Container>
        </header>
      </AppHeaderProvider>
    );
  }
);

AppHeader.displayName = `AppHeader`;
