import { storyblokEditable } from "@hybrbase/storyblok";
import { Grid, GridVariant } from "layout";
import { uid } from "react-uid";
import { SbColumn } from "./SbColumn";

const Components = {
  column: SbColumn,
};

export const SbGrid = ({ blok }) => {
  const { layout } = blok; // Component scheme
  return (
    <Grid variant={GridVariant.Custom} {...storyblokEditable(blok)}>
      {layout?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Grid>
  );
};
