export enum HeadingParts {
  Root = "Root",
}

export enum HeadingVariant {
  Hero = "hero",
  Display = "display",
  Section = "section",
  Feature = "feature",
  Card = "card",
  List = "list",
}

export enum HeadingSize {
  Xxxxl = "xxxxl",
  Xxxl = "xxxl",
  Xxl = "xxl",
  Xl = "xl",
  Lg = "lg",
  Md = "md",
  Base = "base",
  Sm = "sm",
  Fluid = "fluid",
}

export enum HeadingContrast {
  Higher = "higher",
  High = "high",
  Medium = "medium",
}

export enum HeadingWeight {
  Regular = "regular",
  Bold = "bold",
}
