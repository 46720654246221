import { createContext } from "@hybrbase/system";

export interface UseAppHeaderProps {}

/**
 * AppHeader hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useAppHeader = (props: UseAppHeaderProps) => {
  const {} = props;

  return {};
};

export type UseAppHeaderReturn = ReturnType<typeof useAppHeader>;

const [AppHeaderProvider, useAppHeaderContext] =
  createContext<UseAppHeaderReturn>({
    name: "AppHeaderContext",
    errorMessage:
      "useAppHeaderContext: `context` is undefined. Seems you forgot to wrap all AppHeader's components within <AppHeader />",
  });

export { AppHeaderProvider, useAppHeaderContext };
