import getConfig from "next/config";
import { assureTrailingSlash } from "@/utils";
import { IncomingMessage } from "http";
import absoluteUrl from "next-absolute-url";
const { publicRuntimeConfig } = getConfig();

const availableLocales: string[] = publicRuntimeConfig.AVAILABLE_LOCALES;

export interface LanguageAlternate {
  hrefLang: string;
  href: string;
}

export const getLanguageAlternates = async (
  slug: string,
  req: IncomingMessage
): Promise<LanguageAlternate[]> => {
  const { host, protocol } = absoluteUrl(req);

  const languageAlternates: Array<LanguageAlternate> = availableLocales.map(
    (alternate) => {
      // remove the language subdomain by splitting the host "de.ryte.com" on dot and keeping all parts except for the first
      const [, ...parts] = host.split(".");
      return {
        hrefLang: alternate,
        href: assureTrailingSlash(
          `${protocol}//${[alternate, ...parts].join(".")}${slug}`
        ),
      };
    }
  );

  return languageAlternates;
};
