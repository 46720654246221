export enum TextWithVideoParts {
  Root = "Root",
  GridLayout = "GridLayout",
  Tile = "Tile",
  TileLayer = "TileLayer",
  TextContainer = "TextContainer",
  TextContainerTwo = "TextContainerTwo",
  Text = "Text",
  VideoWrapper = "VideoWrapper",
  Video = "Video",
  Container = "Container",
  Grid = "Grid",
  GridItemOne = "GridItemOne",
  GridItemTwo = "GridItemTwo",
  GridItemThree = "GridItemThree",
  InvertedRoundedCornor = "InvertedRoundedCornor",
}

export enum TextWithVideoVariant {
  Default = "default",
  WithBanner = "with-banner",
  WithArticle = "with-article",
}
