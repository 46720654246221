import {
  VideoParts,
  VideoPosition,
  VideoVariant,
} from "../types/Video.constants";
import { TVideoConfigProps } from "../types/Video.types";

export const VideoConfig: TVideoConfigProps = {
  parts: Object.values(VideoParts),
  css: {
    variants: {
      [VideoVariant.Default]: {
        Root: "bg-primary",
        Wrapper: "absolute top-0 left-0 w-full h-full",
      },
    },
    props: {
      position: {
        [VideoPosition.Left]: {
          Wrapper: "object-left",
        },
        [VideoPosition.Center]: {
          Wrapper: "object-center",
        },
        [VideoPosition.Right]: {
          Wrapper: "object-right",
        },
      },
    },
  },
};
