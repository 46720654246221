export function canUseDOM(): boolean {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

export const isBrowser = canUseDOM();

export function toBase64(str: string) {
  if (typeof window === "undefined") {
    return Buffer.from(str).toString("base64");
  }
  return window.btoa(str);
}

interface ClientRect {
  bottom: number;
  readonly height: number;
  left: number;
  right: number;
  top: number;
  readonly width: number;
}

export function calculateVerticalPercentage(
  bounds: ClientRect,
  threshold = 0,
  root: Window | Element | null | undefined = window
) {
  if (!root) return 0;
  const vh =
    (root instanceof Element ? root.clientHeight : root.innerHeight) || 0;
  const offset = threshold * bounds.height;
  const percentage =
    (bounds.bottom - offset) / (vh + bounds.height - offset * 2);

  return 1 - Math.max(0, Math.min(1, percentage));
}

export function calculateHorizontalPercentage(
  bounds: ClientRect,
  threshold = 0,
  root: Window | Element | null | undefined = window
) {
  if (!root) return 0;
  const vw =
    (root instanceof Element ? root.clientWidth : root.innerWidth) || 0;
  const offset = threshold * bounds.width;
  const percentage = (bounds.right - offset) / (vw + bounds.width - offset * 2);

  return 1 - Math.max(0, Math.min(1, percentage));
}
