import { ImageConfig } from "../styles/Image.config";
import { ImageVariant } from "../types/Image.constants";
import { useImage } from "../utils/use-image";
import {
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import NextImage from "next/image";
import { ImageProps as NextImageProps } from "next/image";
import React from "react";

export interface ImageData {
  /**
   * Fallback image `src` to show if image is loading or image fails.
   *
   */
  fallbackSrc?: string;
  /**
   * Alternative text, passed to the <img> tag. Required for accessibility.
   */
  fallbackAlt?: string;
}

export interface ImageOptions extends NextImageProps {
  variant?: ImageVariant;
  /**
   * Fallback element to show if image is loading or image fails.
   */
  fallbackElement?: React.ReactElement;
}
export interface ImageProps extends ImageOptions, ImageData {}

type ImageParts = ForwardRefComponent<"div", ImageProps>;

/**
 * When you use Image component and you're not certain of the source domain of the image (i.e. user input) use
 * make sure to use the `unoptimized` prop. Otherwise declare the domain of the image in the `next.config.js`
 * @see https://nextjs.org/docs/api-reference/next/image#domains
 */
export const Image: ImageParts = forwardRef<ImageProps, "div">((props, ref) => {
  const {
    variant = ImageVariant.Default,
    loading,
    src,
    layout = "intrinsic",
    alt,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    fallbackSrc,
    fallbackAlt,
    fallbackElement,
    unoptimized = true,
    className,
    ...rest
  } = props;

  const shouldIgnore =
    loading != null ||
    fallbackSrc === undefined ||
    fallbackElement === undefined; // if the user doesn't provide any kind of fallback we should ignore it

  const status = useImage({
    src: src as string,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    // if the user doesn't provide any kind of fallback we should ignore it
    ignoreFallback: shouldIgnore,
  });

  const { styles } = useCompConfig(ImageConfig, { variant });

  const sharedProps = {
    layout,
    unoptimized,
  };

  /**
   * If src isn't passed return null,
   */
  if (!src) return null;

  if (status !== "loaded") {
    /**
     * If user passed a custom fallback component,
     * let's render it here.
     */
    if (fallbackElement) return fallbackElement;

    if (fallbackSrc) {
      return (
        <div className={cx(styles.Root, className)} ref={ref}>
          <NextImage
            src={fallbackSrc}
            alt={fallbackAlt}
            {...sharedProps}
            {...rest}
          />
        </div>
      );
    }
  }

  return (
    <div className={cx(styles.Root, className)} ref={ref}>
      <NextImage src={src} alt={alt} {...sharedProps} {...rest} />
    </div>
  );
});

Image.displayName = `Image`;
