import {
  AspectRatioLayout,
  AspectRatioParts,
  AspectRatioPosition,
  AspectRatioSize,
  AspectRatioVariant,
} from "../types/AspectRatio.constants";
import { TAspectRatioConfigProps } from "../types/AspectRatio.types";

export const AspectRatioConfig: TAspectRatioConfigProps = {
  parts: Object.values(AspectRatioParts),
  css: {
    variants: {
      [AspectRatioVariant.Default]: {
        Root: "relative block overflow-hidden",
        Content:
          "absolute top-0 left-0 max-w-none min-h-full min-w-full w-full flex",
      },
      [AspectRatioVariant.Inherit]: {
        Root: "block overflow-hidden",
        Content:
          "absolute top-0 left-0 max-w-none min-h-full min-w-full w-full flex",
        props: {
          size: {
            [AspectRatioSize.Square]: { Root: null },
            [AspectRatioSize.TwoDivideByOne]: { Root: null },
            [AspectRatioSize.TwoDivideByThree]: { Root: null },
            [AspectRatioSize.ThreeDivideByOne]: { Root: null },
            [AspectRatioSize.ThreeDivideByTwo]: { Root: null },
            [AspectRatioSize.FourDivideByThree]: { Root: null },
            [AspectRatioSize.SixteenDivideByNine]: { Root: null },
            [AspectRatioSize.SixteenDivideByTen]: { Root: null },
            [AspectRatioSize.TwentyOneDivideByNine]: { Root: null },
          },
        },
      },
    },
    props: {
      layout: {
        [AspectRatioLayout.Contain]: {
          Root: "aspect-ratio-contain",
        },
        [AspectRatioLayout.Cover]: {
          Root: "aspect-ratio-cover",
        },
      },
      size: {
        [AspectRatioSize.Square]: { Root: "aspect-square" },
        [AspectRatioSize.TwoDivideByOne]: { Root: "aspect-2/1" },
        [AspectRatioSize.TwoDivideByThree]: { Root: "aspect-2/3" },
        [AspectRatioSize.ThreeDivideByOne]: { Root: "aspect-3/1" },
        [AspectRatioSize.ThreeDivideByTwo]: { Root: "aspect-3/2" },
        [AspectRatioSize.FourDivideByThree]: { Root: "aspect-4/3" },
        [AspectRatioSize.SixteenDivideByNine]: { Root: "aspect-16/9" },
        [AspectRatioSize.SixteenDivideByTen]: { Root: "aspect-16/9" },
        [AspectRatioSize.TwentyOneDivideByNine]: { Root: "aspect-21/9" },
      },
      position: {
        [AspectRatioPosition.LeftTop]: {
          Content: "justify-end items-start",
        },
        [AspectRatioPosition.LeftCenter]: {
          Content: "justify-start items-center",
        },
        [AspectRatioPosition.LeftBottom]: {
          Content: "justify-start items-end",
        },
        [AspectRatioPosition.CenterTop]: {
          Content: "justify-center items-start",
        },
        [AspectRatioPosition.CenterCenter]: {
          Content: "justify-center items-center",
        },
        [AspectRatioPosition.CenterBottom]: {
          Content: "justify-center items-end",
        },
        [AspectRatioPosition.RightTop]: {
          Content: "justify-end items-start",
        },
        [AspectRatioPosition.RightCenter]: {
          Content: "justify-end items-center",
        },
        [AspectRatioPosition.RightBottom]: {
          Content: "justify-end items-end",
        },
      },
    },
  },
};
