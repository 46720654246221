import { GridConfig } from "../styles/Grid.config";
import { GridColumns, GridGap, GridVariant } from "../types/Grid.constants";
import { GridProvider, useGrid, UseGridProps } from "../utils/use-grid";
import { GridItem } from "./GridItem";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
  getValidChildren,
} from "@hybrbase/system";
import React from "react";
import { uid } from "react-uid";

export interface GridData {}

export interface GridOptions extends UseGridProps {
  gap?: GridGap;
  columns?: GridColumns;
}
export interface GridProps
  extends Omit<HTMLProps<"div">, keyof GridData>,
    GridOptions,
    GridData {}

type GridParts = ForwardRefComponent<"div", GridProps> & {
  Item?: typeof GridItem;
};

export const Grid: GridParts = forwardRef<GridProps, "div">((props, ref) => {
  const {
    variant = GridVariant.Default,
    gap = GridGap.Md,
    columns = GridColumns.Four,
    className,
    children,
    ...rest
  } = props;

  const { styles } = useCompConfig(GridConfig, {
    variant,
    css: { gap, columns },
  });

  const { ...ctx } = useGrid({ variant });
  const context = React.useMemo(() => ({ variant, ...ctx }), [variant, ctx]);

  return (
    <GridProvider value={context}>
      <div className={cx(styles.Root, className)} ref={ref} {...rest}>
        {children &&
          getValidChildren(children).map((child, index) => {
            return <React.Fragment key={uid(index)}>{child}</React.Fragment>;
          })}
      </div>
    </GridProvider>
  );
});

Grid.Item = GridItem;

Grid.displayName = "Grid";
Grid.Item.displayName = "Grid.Item";
