import {
  GridColumns,
  GridGap,
  GridParts,
  GridVariant,
} from "../types/Grid.constants";
import { TGridConfigProps } from "../types/Grid.types";
import { GridItemOffset, GridItemSize } from "../types/GridItem.constants";

export const GridConfig: TGridConfigProps = {
  parts: Object.values(GridParts),
  css: {
    variants: {
      [GridVariant.Default]: {
        Root: "flex-grid",
      },
      [GridVariant.Custom]: {
        Root: "flex-grid",
        props: {
          size: {
            [GridItemSize.One]: {
              Item: "flex-col-1",
            },
            [GridItemSize.Two]: {
              Item: "flex-col-2",
            },
            [GridItemSize.Three]: {
              Item: "flex-col-3",
            },
            [GridItemSize.Four]: {
              Item: "flex-col-4",
            },
          },
        },
      },
    },
    props: {
      columns: {
        [GridColumns.Four]: {
          Root: "flex-grid-2 sm:flex-grid-4",
        },
        [GridColumns.Six]: {
          Root: "flex-grid-2 sm:flex-grid-4 md:flex-grid-6",
        },
        [GridColumns.Custom]: {
          Root: null,
        },
      },
      gap: {
        [GridGap.Xs]: {
          Root: "flex-gap-xs",
        },
        [GridGap.Sm]: {
          Root: "flex-gap-sm",
        },
        [GridGap.Md]: {
          Root: "flex-gap-md",
        },
        [GridGap.Lg]: {
          Root: "flex-gap-lg",
        },
        [GridGap.Xl]: {
          Root: "flex-gap-xl",
        },
      },
      size: {
        [GridItemSize.One]: {
          Item: "flex-col-1",
        },
        [GridItemSize.Two]: {
          Item: "flex-col-2",
        },
        [GridItemSize.Three]: {
          Item: "md:flex-col-3",
        },
        [GridItemSize.Four]: {
          Item: "flex-col-4",
        },
        [GridItemSize.Five]: {
          Item: "flex-col-5",
        },
        [GridItemSize.Six]: {
          Item: "flex-col-6",
        },
        [GridItemSize.Seven]: {
          Item: "flex-col-7",
        },
        [GridItemSize.Eight]: {
          Item: "flex-col-8",
        },
        [GridItemSize.Nine]: {
          Item: "flex-col-9",
        },
        [GridItemSize.Ten]: {
          Item: "flex-col-10",
        },
        [GridItemSize.Eleven]: {
          Item: "flex-col-11",
        },
        [GridItemSize.Twelve]: {
          Item: "flex-col-12",
        },
      },
      offset: {
        [GridItemOffset.One]: {
          Item: "sm:flex-offset-1",
        },
        [GridItemOffset.Two]: {
          Item: "flex-offset-2",
        },
        [GridItemOffset.Three]: {
          Item: "flex-offset-3",
        },
        [GridItemOffset.Four]: {
          Item: "flex-offset-4",
        },
        [GridItemOffset.Five]: {
          Item: "flex-offset-5",
        },
        [GridItemOffset.Six]: {
          Item: "flex-offset-6",
        },
        [GridItemOffset.Seven]: {
          Item: "flex-offset-7",
        },
        [GridItemOffset.Eight]: {
          Item: "flex-offset-8",
        },
        [GridItemOffset.Nine]: {
          Item: "flex-offset-9",
        },
        [GridItemOffset.Ten]: {
          Item: "flex-offset-10",
        },
        [GridItemOffset.Eleven]: {
          Item: "flex-offset-11",
        },
        [GridItemOffset.Twelve]: {
          Item: "flex-offset-12",
        },
      },
    },
  },
};
