import * as React from "react";

export enum HydrationMode {
  SSR_ONLY = "SSR_ONLY",
  WHEN_IDLE = "WHEN_IDLE",
  WHEN_VISIBLE = "WHEN_VISIBLE",
}

export type LazyHydrateProps = Omit<
  React.HTMLProps<HTMLDivElement>,
  "dangerouslySetInnerHTML"
> &
  React.PropsWithChildren<{
    mode: HydrationMode;
    noWrapper?: boolean | keyof JSX.IntrinsicElements;
  }>;

export type VoidFunction = () => void;
