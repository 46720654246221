import React from "react";

import dashify from "dashify";
import { ISbComponents, SbBlokProps } from "@hybrbase/storyblok";
import {
  SbBannerTextWithVideo,
  SbFeatures,
  SbHero,
  SbIntro,
  SbStepFeatures,
  SbTestimonial,
  SbTextWithMedia,
  SbTextWithVideo,
} from "./sections";
import { SbSection } from "./layout";
import { HydrationMode, withLazyHydration } from "../lazy-hydration";

const SbComponents: ISbComponents = {
  // layout
  section: SbSection,
  // sections
  hero: SbHero,
  intro: SbIntro,
  features: SbFeatures,
  step_features: SbStepFeatures,
  testimonial: withLazyHydration(HydrationMode.WHEN_VISIBLE, SbTestimonial),
  text_with_media: SbTextWithMedia,
  banner_text_with_video: SbBannerTextWithVideo,
  text_with_video: SbTextWithVideo,
};

export const SbComponent: React.FC<SbBlokProps> = ({ blok }) => {
  if (blok) {
    const componentName = dashify(blok.component);
    if (typeof SbComponents[componentName] !== "undefined") {
      const FoundComponent = SbComponents[componentName];
      return <FoundComponent blok={blok} />;
    } else {
      return <p>{componentName} is not yet defined.</p>;
    }
  }
  return null;
};
