import { TextConfig } from "../styles/Text.config";
import {
  TextContrast,
  TextSize,
  TextVariant,
  TextWeight,
} from "../types/Text.constants";
import { TTextHtml } from "../types/Text.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";

const textAsHtml: Record<TextVariant, TTextHtml> = {
  [TextVariant.Body]: `p`,
  [TextVariant.Leading]: `p`,
  [TextVariant.Small]: `p`,
  [TextVariant.Subtitle]: `p`,
};

const textSize: Record<TextVariant, TextSize> = {
  [TextVariant.Body]: TextSize.Base,
  [TextVariant.Leading]: TextSize.Fluid,
  [TextVariant.Small]: TextSize.Fluid,
  [TextVariant.Subtitle]: TextSize.Xs,
};

const textWeight: Record<TextVariant, TextWeight> = {
  [TextVariant.Body]: TextWeight.Regular,
  [TextVariant.Leading]: TextWeight.Regular,
  [TextVariant.Small]: TextWeight.Regular,
  [TextVariant.Subtitle]: TextWeight.Regular,
};

export interface TextData {
  text?: string;
}

export interface TextOptions {
  /**
   * Variants for `Text`. You can extend the variant.
   */
  variant?: TextVariant;
  /**
   * Use to override default Html tag
   */
  as?: TTextHtml;
  size?: TextSize;
  contrast?: TextContrast;
  weight?: TextWeight;
}
export interface TextProps
  extends Omit<HTMLProps<any>, keyof TextData>,
    TextOptions,
    TextData {}

type TextParts = ForwardRefComponent<any, TextProps>;

/**
 * Text are used for text and paragraphs.
 */
export const Text: TextParts = forwardRef<TextProps, any>((props, ref) => {
  const {
    variant = TextVariant.Body,
    size = textSize[variant],
    as = textAsHtml[variant],
    contrast = TextContrast.High,
    weight = textWeight[variant],
    className,
    children,
    ...rest
  } = props;
  const Component = as;

  const { styles } = useCompConfig(TextConfig, {
    variant,
    css: {
      size,
      contrast,
      weight,
    },
  });

  return (
    <Component
      className={cx("font-secondary", styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  );
});

Text.displayName = `Text`;
