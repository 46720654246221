export enum VideoParts {
  Root = "Root",
  Wrapper = "Wrapper",
}

export enum VideoVariant {
  Default = "default",
}

export enum VideoPosition {
  Left = `left`,
  Center = `center`,
  Right = `right`,
}
