import React, { useMemo } from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  getValidChildren,
} from "@hybrbase/system";
import { FeaturesConfig } from "../styles/Features.config";
import { FeaturesVariant } from "../types/Features.constants";
import { Heading, HeadingVariant, Text, TextVariant } from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  GridColumns,
} from "layout";
import { ThemeElement } from "@boilerplate/themes";
import { uid } from "react-uid";
import { featuresFactory } from "../utils/features-factory";

export interface FeaturesData extends SectionData {}

export interface FeaturesOptions {
  variant?: FeaturesVariant;
  theme?: ThemeElement;
}

export interface FeaturesProps
  extends Omit<HTMLProps<"section">, keyof FeaturesData>,
    FeaturesOptions,
    FeaturesData {}

export const Features = forwardRef<FeaturesProps, "section">((props, ref) => {
  const {
    variant = FeaturesVariant.Default,
    className,
    theme = ThemeElement.Default,
    title,
    subtitle,
    text,
    children,
    ...rest
  } = props;

  const { styles } = useCompConfig(FeaturesConfig, { variant });
  const factoryConfig = useMemo(() => featuresFactory(variant), [variant]);

  return (
    <Section
      theme={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <Grid>
          <Grid.Item className={styles.ColumnOne}>
            {subtitle && (
              <TextContainer
                className="mb-sm"
                align={factoryConfig?.textContainer?.align}
              >
                <Text variant={TextVariant.Subtitle}>{subtitle}</Text>
              </TextContainer>
            )}
            <TextContainer align={factoryConfig?.textContainer?.align}>
              <Heading variant={HeadingVariant.Section}>{title}</Heading>
              {text && <Text>{text}</Text>}
            </TextContainer>
          </Grid.Item>
          <Grid.Item className={styles.ColumnTwo}>
            <Grid columns={GridColumns.Custom} className={styles.CustomGrid}>
              {children &&
                getValidChildren(children).map((child, index) => {
                  return (
                    <Grid.Item
                      className={styles.CustomGridItem}
                      key={uid(index)}
                      size={factoryConfig?.gridItem?.size}
                    >
                      {child}
                    </Grid.Item>
                  );
                })}
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
});

Features.displayName = `Features`;
