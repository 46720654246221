import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { TestimonialConfig } from "../styles/Testimonial.config";
import { TestimonialVariant } from "../types/Testimonial.constants";
import {
  Carousel,
  Heading,
  HeadingSize,
  HeadingVariant,
  ImageProps,
  Text,
  TextSize,
  TextVariant,
  Image,
  Video,
  VideoProps,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  AspectRatio,
  AspectRatioSize,
  AspectRatioVariant,
  TextContainerAlign,
  Tile,
  TileSpace,
} from "layout";
import { ThemeElement } from "@boilerplate/themes";
import { uid } from "react-uid";

type Items = {
  text: string;
  image: ImageProps;
  author: {
    name: string;
    role: string;
  };
};

export interface TestimonialData extends SectionData {
  items?: Items[];
  video?: VideoProps;
}

export interface TestimonialOptions {
  variant?: TestimonialVariant;
  theme?: ThemeElement;
}

export interface TestimonialProps
  extends Omit<HTMLProps<"section">, keyof TestimonialData>,
    TestimonialOptions,
    TestimonialData {}

export const Testimonial = forwardRef<TestimonialProps, "section">(
  (props, ref) => {
    const {
      variant = TestimonialVariant.Default,
      className,
      theme = ThemeElement.Default,
      title,
      items,
      video,

      ...rest
    } = props;

    const { styles } = useCompConfig(TestimonialConfig, { variant });

    return (
      <Section
        theme={theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        {video?.src && (
          <Video
            hasPlayInView
            aspectRatio={{ variant: AspectRatioVariant.Inherit }}
            {...video}
          />
        )}

        <Container>
          <Tile
            theme={ThemeElement.Default}
            space={TileSpace.Lg}
            className="rounded-lg"
          >
            <TextContainer align={TextContainerAlign.Center}>
              <Heading
                variant={HeadingVariant.Feature}
                className="font-secondary uppercase"
                size={HeadingSize.Sm}
                as="h2"
              >
                {title}
              </Heading>
            </TextContainer>
            <Carousel>
              {items?.map((item, index) => {
                return (
                  <Carousel.Slide className="py-lg" key={uid(index)}>
                    <TextContainer
                      align={TextContainerAlign.Center}
                      className="max-w-sm mx-auto"
                    >
                      <Heading variant={HeadingVariant.Feature}>
                        {item.text}
                      </Heading>
                    </TextContainer>

                    <TextContainer
                      align={TextContainerAlign.Center}
                      className="mt-fluid-lg"
                    >
                      {item?.image?.src && (
                        <AspectRatio
                          className="w-xxl rounded-full mx-auto bg-primary"
                          size={AspectRatioSize.Square}
                        >
                          <Image layout="fill" {...item?.image} />
                        </AspectRatio>
                      )}
                      <Heading
                        variant={HeadingVariant.Feature}
                        size={HeadingSize.Md}
                      >
                        {item?.author?.name}
                      </Heading>
                      <Text
                        variant={TextVariant.Small}
                        size={TextSize.Sm}
                        className="mt-0"
                      >
                        {item?.author?.role}
                      </Text>
                    </TextContainer>
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          </Tile>
        </Container>
      </Section>
    );
  }
);

Testimonial.displayName = `Testimonial`;
