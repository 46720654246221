export enum GridItemSize {
  One = "one",
  Two = "two",
  Three = "three",
  Four = "four",
  Five = "five",
  Six = "six",
  Seven = "seven",
  Eight = "eight",
  Nine = "nine",
  Ten = "ten",
  Eleven = "eleven",
  Twelve = "twelve",
}

export enum GridItemOffset {
  One = "one",
  Two = "two",
  Three = "three",
  Four = "four",
  Five = "five",
  Six = "six",
  Seven = "seven",
  Eight = "eight",
  Nine = "nine",
  Ten = "ten",
  Eleven = "eleven",
  Twelve = "twelve",
}
