import { TextWithVideo, TextWithVideoVariant } from "sections";
import { getImageService, storyblokEditable } from "@hybrbase/storyblok";
import { replaceAssetHost } from "@/utils";

export const SbTextWithVideo = ({ blok }) => {
  const {
    subtitle,
    title,
    button_label,
    button_src,
    video,
    video_poster_src,
    id,
  } = blok; // Component scheme

  return (
    <TextWithVideo
      subtitle={subtitle}
      title={title}
      video={{
        src: replaceAssetHost(video?.filename),
        alt: video?.alt,
        title: video?.title,
        posterSrc: replaceAssetHost(video_poster_src?.filename),
      }}
      link={{
        title: button_label,
        href: button_src?.cached_url,
        children: button_label,
      }}
      id={id}
      {...storyblokEditable(blok)}
    />
  );
};

export const SbBannerTextWithVideo = ({ blok }) => {
  const {
    subtitle,
    title,
    text,
    button_label,
    video_poster_src,
    button_src,
    video,
  } = blok; // Component scheme

  return (
    <TextWithVideo
      variant={TextWithVideoVariant.WithBanner}
      subtitle={subtitle}
      text={text}
      title={title}
      video={{
        src: video?.filename,
        alt: video?.alt,
        title: video?.title,
        posterSrc: video_poster_src?.filename,
      }}
      link={{
        title: button_label,
        href: button_src?.cached_url,
        children: button_label,
      }}
      {...storyblokEditable(blok)}
    />
  );
};

export const SbArticleTextWithVideo = ({ blok }) => {
  const { subtitle, title, text, video, image, video_poster_src } = blok; // Component scheme
  return (
    <TextWithVideo
      variant={TextWithVideoVariant.WithArticle}
      subtitle={subtitle}
      text={text}
      title={title}
      video={{
        src: video?.filename,
        alt: video?.alt,
        title: video?.title,
        posterSrc: video_poster_src?.filename,
      }}
      image={{
        src: getImageService(image?.filename),
        alt: image?.alt,
        title: image?.title,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
