import { storyblokEditable } from "@hybrbase/storyblok";
import { cx } from "@hybrbase/system";
import { Image } from "elements";
import { AspectRatio } from "layout";

export const SbAspectRatioImage = ({ blok }) => {
  const { variant, image, ratio } = blok; // Component scheme
  return (
    <AspectRatio
      size={ratio}
      className={cx("bg-primary", {
        "rounded-md": variant === "with-rounded",
      })}
      {...storyblokEditable(blok)}
    >
      <Image
        layout="fill"
        className="aspect-ratio-cover"
        src={image?.filename}
        alt={image?.alt}
        title={image?.title}
      />
    </AspectRatio>
  );
};
