import SeoHead from "@/components/seo-head/SeoHead";
import { PageProvider, useThemeContext } from "@hybrbase/system";
import { AppFooter, AppHeader } from "sections";
import { FC, useEffect } from "react";
import {
  SbBlokProps,
  storyblokEditable,
  useStoryblok,
} from "@hybrbase/storyblok";
import { useRouter } from "next/router";
import getConfig from "next/config";
import { useQuery } from "react-query";
import { getSlugStory } from "@/lib/storyblok";
import { fetcher } from "@/utils";
import { isBrowser, m } from "framer-motion";
import { Tile, TileSpace } from "layout";
import { ThemeElement } from "@boilerplate/themes";

const { publicRuntimeConfig } = getConfig();

const filteredLinks = (links) => {
  return links?.map((item) => {
    return {
      icon: item?.icon,
      title: item?.link_label,
      children: item?.link_label,
      href: item?.link_src?.cached_url || "/",
    };
  });
};

export interface PageProps {
  pageProps?: any;
  appHeader?: SbBlokProps;
  appFooter?: SbBlokProps;
  pageContext?: any;
  preview?: boolean;
  queryName?: string;
}

export const Page: FC<PageProps> = ({
  children,
  pageProps,
  pageContext,
  preview,
  queryName,
}) => {
  const { language, resolvedUrl, seo } = pageContext;

  const { asPath, locale } = useRouter();

  const storyQuery = useQuery(
    [
      queryName,
      {
        language,
        resolvedUrl,
      },
    ],
    () => getSlugStory({ language, slug: resolvedUrl })
  );
  const langQuery = useQuery(
    [
      "language-alternates",
      {
        resolvedUrl,
      },
    ],
    () => fetcher(`/api/language-alternates?slug=${resolvedUrl}`)
  );

  const dataStory = useStoryblok(storyQuery.data.story, preview);
  const headerStory = useStoryblok(storyQuery.data.headerStory, preview);
  const footerStory = useStoryblok(storyQuery.data.footerStory, preview);
  const { isPageTransition } = useThemeContext();

  useEffect(() => {
    if (isBrowser) {
      document.body.classList.toggle("overflow-hidden", isPageTransition);
    }
  }, [isPageTransition]);

  return (
    <PageProvider
      value={{
        ...pageContext,
        seo: {
          title: dataStory?.content?.meta_title || dataStory?.name,
          description:
            dataStory?.content?.meta_description || dataStory?.content?.excerpt,
          thumbnail:
            dataStory?.content?.og_image?.filename ||
            dataStory?.content?.thumbnail,
          languageAlternates: langQuery?.data,
          defaultCanonicalUrl: seo?.defaultCanonicalUrl,
        },
      }}
    >
      <SeoHead
        title={dataStory?.content?.meta_title || dataStory?.name}
        description={
          dataStory?.content?.meta_description || dataStory?.content?.excerpt
        }
        thumbnail={
          dataStory?.content?.og_image?.filename ||
          dataStory?.content?.thumbnail
        }
        languageAlternates={langQuery?.data}
        defaultCanonicalUrl={seo?.defaultCanonicalUrl}
        {...seo}
      />
      <AppHeader
        asPath={asPath}
        locale={locale}
        locales={publicRuntimeConfig.AVAILABLE_LOCALES}
        lang={{
          asPath,
          locale,
        }}
        ctaLink={{
          href: headerStory?.content?.cta_link_src.cached_url,
          children: headerStory?.content?.cta_link_label,
          title: headerStory?.content?.cta_link_label,
        }}
        links={filteredLinks(headerStory?.content?.links)}
        {...storyblokEditable(headerStory?.content)}
      />
      <main {...pageProps}>{children}</main>

      <m.div
        data-theme-element={ThemeElement.Default}
        initial="initial"
        animate={isPageTransition ? "enter" : "exit"}
        exit="exit"
        className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-overlay"
        variants={{
          initial: {
            y: "100%",
          },
          enter: {
            y: "0",
            // visibility: "visible",
            // opacity: 1,
          },
          exit: {
            y: "100%",
            // opacity: 0,
            // visibility: "hidden",
          },
        }}
        transition={{
          duration: 0.6,
          ease: [0.16, 1, 0.3, 1],
        }}
      >
        <Tile space={TileSpace.Sm}>
          {isPageTransition && (
            <div className="la-ball-clip-rotate">
              <div></div>
            </div>
          )}
        </Tile>
      </m.div>
      <AppFooter
        socialLinks={filteredLinks(footerStory?.content?.social_links)}
        colophonLinks={filteredLinks(footerStory?.content?.colophon_links)}
        links={filteredLinks(footerStory?.content?.links)}
        {...storyblokEditable(footerStory?.content)}
      />
    </PageProvider>
  );
};
