import { storyblokEditable } from "@hybrbase/storyblok";
import { Container } from "layout";
import { uid } from "react-uid";
import { SbGrid } from "./SbGrid";

const Components = {
  grid: SbGrid,
};

export const SbContainer = ({ blok }) => {
  const { variant, layout } = blok; // Component scheme
  return (
    <Container variant={variant} {...storyblokEditable(blok)}>
      {layout?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Container>
  );
};
