import { useEffect, useLayoutEffect, useState } from "react";
import { isBrowser } from "utils";

const useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

const getInitialState = (query: string, defaultState?: boolean) => {
  // Prevent a React hydration mismatch when a default value is provided by not defaulting to window.matchMedia(query).matches.
  if (defaultState !== undefined) {
    return defaultState || false;
  }

  if (isBrowser) {
    return window.matchMedia(query).matches;
  }

  return false;
};

export const useBreakpoint = (bpVal: number, defaultState?: boolean) => {
  const [state, setState] = useState(
    getInitialState(`(min-width: ${bpVal}px)`, defaultState)
  );

  useSafeLayoutEffect(() => {
    if (!isBrowser) return;

    let mounted = true;
    const mql = window.matchMedia(`(min-width: ${bpVal}px)`);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [bpVal]);

  return state;
};
