import { HeroConfig } from "../styles/Hero.config";
import { HeroVariant } from "../types/Hero.constants";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { ThemeElement } from "@boilerplate/themes";
import {
  Heading,
  HeadingVariant,
  Button,
  ButtonVariant,
  LinkProps,
  ButtonTheme,
  IconName,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  SectionYSpace,
} from "layout";

export interface HeroData extends SectionData {
  link?: LinkProps;
}

export interface HeroOptions {
  variant?: HeroVariant;
  theme?: ThemeElement;
}

export interface HeroProps
  extends Omit<HTMLProps<"section">, keyof HeroData>,
    HeroOptions,
    HeroData {}

export const Hero = forwardRef<HeroProps, "section">((props, ref) => {
  const {
    variant = HeroVariant.Default,
    className,
    title,
    videoSrc,
    videoPosterSrc,
    link,
    scrollTo,
    ...rest
  } = props;

  const { styles } = useCompConfig(HeroConfig, { variant });

  return (
    <Section
      className={cx(styles.Root, className)}
      ySpace={SectionYSpace.None}
      ref={ref}
      {...rest}
    >
      <Container className={styles.Container}>
        <Grid>
          <Grid.Item>
            <TextContainer className={styles.TextContainer} vSpace={2}>
              <Heading className={styles.Heading} variant={HeadingVariant.Hero}>
                {title}
              </Heading>

              <div className="flex flex-col space-y-md sm:flex-row sm:space-y-0 sm:space-x-md justify-center items-center">
                {link && (
                  <div>
                    <Button.Link
                      variant={ButtonVariant.Outline}
                      theme={ButtonTheme.Accent}
                      iconName={IconName.ArrowRight}
                      {...link}
                    >
                      {link?.children}
                    </Button.Link>
                  </div>
                )}
              </div>
            </TextContainer>
          </Grid.Item>
        </Grid>
      </Container>
    </Section>
  );
});
