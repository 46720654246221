import * as Sentry from "@sentry/nextjs";

// Handle specific error appropriately
export class ApiError extends Error {
  status: any;
  constructor(url, status) {
    super(`'${url}' returned ${status}`);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.name = "ApiError";
    this.status = status;
  }
}

export async function fetcher(url, options?: { [key in string]: any }) {
  const response = await fetch(url, options);
  if (!response.ok) {
    Sentry.captureException(response.status);
    throw new ApiError(url, response.status);
  }
  return await response.json();
}
