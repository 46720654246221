import React, { useMemo } from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { TextWithVideoConfig } from "../styles/TextWithVideo.config";
import { TextWithVideoVariant } from "../types/TextWithVideo.constants";
import {
  Button,
  ButtonTheme,
  ButtonVariant,
  Heading,
  IconName,
  ImageProps,
  LinkProps,
  Text,
  TextVariant,
  Video,
  VideoProps,
  Image,
  Icon,
  IconSize,
  VideoVariant,
  VideoPlayer,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  Tile,
  AspectRatio,
  AspectRatioSize,
} from "layout";
import { ThemeElement } from "@boilerplate/themes";
import { textWithVideoFactory } from "../utils/text-with-video-factory";

export interface TextWithVideoData extends SectionData {
  link?: LinkProps;
  video?: VideoProps;
  image?: ImageProps;
}

export interface TextWithVideoOptions {
  variant?: TextWithVideoVariant;
  theme?: ThemeElement;
}

export interface TextWithVideoProps
  extends Omit<HTMLProps<"section">, keyof TextWithVideoData>,
    TextWithVideoOptions,
    TextWithVideoData {}

export const TextWithVideo = forwardRef<TextWithVideoProps, "section">(
  (props, ref) => {
    const {
      variant = TextWithVideoVariant.Default,
      className,
      theme = ThemeElement.Default,
      title,
      subtitle,
      text,
      link,
      video,
      image,
      ...rest
    } = props;

    const { styles } = useCompConfig(TextWithVideoConfig, { variant });
    const factoryConfig = useMemo(
      () => textWithVideoFactory(variant),
      [variant]
    );

    return (
      <Section
        theme={theme}
        className={cx(styles.Root, className)}
        ySpace={factoryConfig?.section?.ySpace}
        ref={ref}
        {...rest}
      >
        {video?.src && (
          <div className={styles.VideoWrapper}>
            {variant === VideoVariant.Default ? (
              <VideoPlayer
                className={styles.Video}
                src={video?.src}
                poster={video?.posterSrc}
              />
            ) : (
              <Video
                hasPlayInView
                aspectRatio={{ variant: factoryConfig?.video?.aspectRatio }}
                className={styles.Video}
                {...video}
              />
            )}
          </div>
        )}
        <Container className={styles.Container}>
          <Grid className={styles.GridLayout}>
            <Grid.Item offset={factoryConfig?.gridItemLayout?.offset}>
              <Tile theme={ThemeElement.Default} className={styles.Tile}>
                <div className={styles.TileLayer} />

                {styles.InvertedRoundedCornor && (
                  <Icon
                    className={styles.InvertedRoundedCornor}
                    size={IconSize.Lg}
                    name={IconName.Inverted}
                  />
                )}

                <Grid className={styles.Grid} gap={factoryConfig?.grid?.gap}>
                  <Grid.Item className={styles.GridItemOne}>
                    <TextContainer
                      align={factoryConfig?.textContainer?.align}
                      className={styles.TextContainer}
                    >
                      {subtitle && (
                        <Text variant={TextVariant.Subtitle}>{subtitle}</Text>
                      )}

                      <Heading
                        variant={factoryConfig?.heading?.variant}
                        className="mt-0"
                      >
                        {title}
                      </Heading>
                    </TextContainer>
                  </Grid.Item>

                  {image && (
                    <Grid.Item className={styles.GridItemTwo}>
                      <AspectRatio
                        size={AspectRatioSize.FourDivideByThree}
                        className="rounded-md md:mt-xxl ml-lg mr-lg md:mr-0 max-w-xxxxs md:max-w-md bg-primary"
                      >
                        <Image
                          layout="fill"
                          className="aspect-ratio-cover"
                          {...image}
                        />
                      </AspectRatio>
                    </Grid.Item>
                  )}
                  <Grid.Item className={styles.GridItemThree}>
                    <TextContainer
                      align={factoryConfig?.textContainer?.align}
                      className={styles.TextContainerTwo}
                    >
                      <Text className={styles.Text}>{text}</Text>
                      {link?.children && (
                        <Button.Link
                          variant={ButtonVariant.Outline}
                          theme={ButtonTheme.Accent}
                          iconName={IconName.ArrowRight}
                          {...link}
                        >
                          {link?.children}
                        </Button.Link>
                      )}
                    </TextContainer>
                  </Grid.Item>
                </Grid>
              </Tile>
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
);

TextWithVideo.displayName = `TextWithVideo`;
