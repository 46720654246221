import { GridVariant } from "../types/Grid.constants";
import { createContext } from "@hybrbase/system";

export interface UseGridProps {
  /**
   * Variants for `Grid`. You can extend the variant.
   */
  variant?: GridVariant;
}

/**
 * Grid hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useGrid = (props: UseGridProps) => {
  const { variant } = props;

  return {
    variant,
  };
};

export type UseGridReturn = ReturnType<typeof useGrid>;

const [GridProvider, useGridContext] = createContext<UseGridReturn>({
  name: "GridContext",
  errorMessage:
    "useGridContext: `context` is undefined. Seems you forgot to wrap all Grid's components within <Grid />",
});

export { GridProvider, useGridContext };
