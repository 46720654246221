import React from "react";
import { VideoConfig } from "../styles/VideoPlayer.config";
import { VideoPlayerVariant } from "../utils/VideoPlayer.constants";
import {
  UseVideoPlayerProps,
  useVideoPlayerContext,
} from "../utils/use-video-player";
import { cx, useCompConfig } from "@hybrbase/system";
import noop from "no-op";

export interface VideoPlayerTimelineData {
  inputAriaLabel?: string;
}

export interface VideoPlayerTimelineOptions {
  currentTime?: number;
  duration: number;
  onTimeUpdate?: (currentTime: number, currentDuration: number) => void;
  step?: number;
  stepFast?: number;
}
export interface VideoPlayerTimelineProps
  extends VideoPlayerTimelineOptions,
    VideoPlayerTimelineData,
    UseVideoPlayerProps {
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 */
export const VideoPlayerTimeline: React.FC<VideoPlayerTimelineProps> = ({
  className,
  children,
  currentTime: currTime,
  duration,
  onTimeUpdate = noop,
  step = 0.1,
  stepFast = 5,
  inputAriaLabel = "Seek  Video",
  ...rest
}) => {
  const inputRef = React.useRef(null);

  const [currentTime, seCurrentTime] = React.useState(currTime);
  const [currStep, setCurrStep] = React.useState(step);

  React.useEffect(() => {
    seCurrentTime(currTime);
  }, [currTime]);

  function onChange() {
    seCurrentTime(parseFloat(inputRef.current.value));
    onTimeUpdate(inputRef.current.value, inputRef.current.value / duration);
  }

  const { variant } = useVideoPlayerContext();
  const { styles } = useCompConfig(VideoConfig, { variant });

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoPlayerVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-player-timeline-height": "3px",
            "--video-player-timeline-radius": "50rem",
            "--video-player-timeline-thumb-size": "1rem",
            "--video-player-timeline-thumb-color": `var(--oc-color-primary)`,
            "--video-player-timeline-progress-color": `var(--oc-color-primary)`,
            "--video-player-timeline-track-color": `var(--oc-color-primary)`,
            "--video-player-timeline-range-fill-value": "0%",
            "--video-player-timeline-range-empty-value": "100%",
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant]);

  return (
    <div
      style={rootStyle}
      className={cx("video-player-timeline", styles.Timeline, className)}
      {...rest}
    >
      <div
        className={cx(
          "video-player-timeline-progress",
          styles.TimelineProgress
        )}
        style={{ width: (currentTime / duration) * 100 + "%" }}
      />
      <input
        className={cx(
          "video-player-timeline-input-range",
          styles.TimelineInputRange
        )}
        type="range"
        ref={inputRef}
        min="0"
        max={duration}
        step={currStep}
        onChange={onChange}
        value={currentTime}
        onKeyDown={() => setCurrStep(stepFast)}
        onKeyUp={() => setCurrStep(step)}
        onBlur={() => setCurrStep(step)}
        aria-label={inputAriaLabel}
        title={inputAriaLabel}
      />
    </div>
  );
};

VideoPlayerTimeline.displayName = `VideoPlayerTimeline`;
