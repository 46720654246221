import React from "react";
import { cx, useCompConfig } from "@hybrbase/system";
import { VideoConfig } from "../styles/VideoPlayer.config";
import {
  UseVideoPlayerProps,
  useVideoPlayerContext,
} from "../utils/use-video-player";
import {
  IconName,
  Button,
  Icon,
  ButtonVariant,
  IconSize,
} from "../../../index";
import noop from "no-op";
import { VideoPlayerTimeline } from "./VideoPlayerTimeline";
import { VideoPlayerVariant } from "../index";

export interface VideoPlayerControlsData {
  exitFullscreenLabel?: string;
  enterFullscreenLabel?: string;
  navAriaLabel?: string;
  playLabel?: string;
  pauseLabel?: string;
  captionsHideLabel?: string;
  captionsShowLabel?: string;
  unmuteLabel?: string;
  muteLabel?: string;
  playIcon?: IconName;
  pauseIcon?: IconName;
  mutedIcon?: IconName;
  unmutedIcon?: IconName;
  exitFullscreenIcon?: IconName;
  enterFullscreenIcon?: IconName;
  captionsOnIcon?: IconName;
  captionsOffIcon?: IconName;
}

export interface VideoPlayerControlsOptions {
  captions?: boolean;
  duration: number;
  currentTime?: number;
  isPlaying?: boolean;
  isShowingCaptions?: boolean;
  isMuted?: boolean;
  isFullScreen?: boolean;
  onPlayToggle?: () => void;
  onTimeUpdate?: (currentTime: number) => void;
  onCaptionsToggle?: () => void;
  onMuteToggle?: () => void;
  onFullscreenToggle?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}
export interface VideoPlayerControlsProps
  extends VideoPlayerControlsOptions,
    VideoPlayerControlsData,
    UseVideoPlayerProps {
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 */
export const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = ({
  className,
  children,
  playIcon = IconName.Play,
  pauseIcon = IconName.Pause,
  captionsOnIcon = IconName.CaptionsOn,
  captionsOffIcon = IconName.CaptionsOff,
  mutedIcon = IconName.Muted,
  unmutedIcon = IconName.Unmuted,
  exitFullscreenIcon = IconName.ExitFullscreen,
  enterFullscreenIcon = IconName.EnterFullscreen,
  captions,
  duration,
  currentTime,
  onPlayToggle = noop,
  isPlaying,
  onTimeUpdate = noop,
  isShowingCaptions,
  onCaptionsToggle = noop,
  isMuted,
  onMuteToggle = noop,
  isFullScreen,
  onFullscreenToggle = noop,
  navAriaLabel = "Video Controls",
  playLabel = "Play Video",
  pauseLabel = "Pause Video",
  captionsHideLabel = "Hide Captions",
  captionsShowLabel = "Show Captions",
  unmuteLabel = "Unmute Video",
  muteLabel = "Mute Video",
  exitFullscreenLabel = "Exit Fullscreen Mode",
  enterFullscreenLabel = "Enter Fullscreen Mode",
  onFocus = noop,
  onBlur = noop,
  ...rest
}) => {
  function formatTime(totalSeconds) {
    const totalSecondsFloat = totalSeconds;
    const minutes = Math.floor(totalSecondsFloat / 60);
    const seconds = Math.round(totalSecondsFloat - minutes * 60);
    let minutesValue;
    let secondsValue;

    if (minutes < 10) {
      minutesValue = `0${minutes}`;
    } else {
      minutesValue = minutes;
    }
    if (seconds < 10) {
      secondsValue = `0${seconds}`;
    } else {
      secondsValue = seconds;
    }

    return `${minutesValue}:${secondsValue}`;
  }

  const isFullscreenAPISupported: Document = React.useMemo(() => {
    const document: any = window.document;

    return (
      document.body.requestFullscreen ||
      document.body.mozRequestFullScreen ||
      document.body.webkitRequestFullscreen ||
      document.body.webkitEnterFullScreen ||
      document.body.msRequestFullscreen
    );
  }, []);
  const { variant } = useVideoPlayerContext();
  const { styles } = useCompConfig(VideoConfig, { variant });

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoPlayerVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-player-controls-button-width": `var(--oc-spacing-md)`,
            "--video-player-controls-button-height": `var(--oc-spacing-md)`,
            "--video-player-controls-button-color": `var(--oc-color-primary)`,
            "--video-player-controls-button-margin": `var(--oc-spacing-md)`,
            "--video-player-controls-height": `var(--oc-spacing-xl)`,
            "--video-player-controls-bg-color": `#FFF`,
            "--video-player-controls-time-color": `var(--oc-color-primary)`,
            "--video-player-controls-time-width": `var(--oc-spacing-xl)`,
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant]);

  return (
    <nav
      // css={VideoControlsStyles}
      style={rootStyle}
      className={cx("video-player-controls", styles.Controls, className)}
      {...rest}
      aria-label={navAriaLabel}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Button
        variant={ButtonVariant.Reset}
        className={cx("video-player-controls-button", styles.ControlsButton)}
        title={isPlaying ? pauseLabel : playLabel}
        onClick={onPlayToggle}
      >
        <Icon
          name={isPlaying ? pauseIcon : playIcon}
          title={isPlaying ? pauseLabel : playLabel}
          size={IconSize.Md}
        />
      </Button>

      <VideoPlayerTimeline
        duration={duration}
        currentTime={Number(currentTime)}
        onTimeUpdate={onTimeUpdate}
      />

      <time className={cx("video-player-controls-time", styles.ControlsTime)}>
        {formatTime(Number(currentTime))}
      </time>

      {captions && (
        <Button
          variant={ButtonVariant.Reset}
          className={cx("video-player-controls-button", styles.ControlsButton)}
          title={isShowingCaptions ? captionsHideLabel : captionsShowLabel}
          onClick={onCaptionsToggle}
        >
          <Icon
            name={isShowingCaptions ? captionsOnIcon : captionsOffIcon}
            title={isShowingCaptions ? captionsHideLabel : captionsShowLabel}
            size={IconSize.Md}
          />
        </Button>
      )}

      <Button
        variant={ButtonVariant.Reset}
        className={cx("video-player-controls-button", styles.ControlsButton)}
        title={isMuted ? unmuteLabel : muteLabel}
        onClick={onMuteToggle}
      >
        <Icon
          name={isMuted ? mutedIcon : unmutedIcon}
          title={isMuted ? unmuteLabel : muteLabel}
          size={IconSize.Md}
        />
      </Button>

      {isFullscreenAPISupported && (
        <Button
          variant={ButtonVariant.Reset}
          className={cx("video-player-controls-button", styles.ControlsButton)}
          title={isFullScreen ? exitFullscreenLabel : enterFullscreenLabel}
          onClick={onFullscreenToggle}
        >
          <Icon
            name={isFullScreen ? exitFullscreenIcon : enterFullscreenIcon}
            title={isFullScreen ? exitFullscreenLabel : enterFullscreenLabel}
            size={IconSize.Md}
          />
        </Button>
      )}
    </nav>
  );
};

VideoPlayerControls.displayName = `VideoPlayerControls`;
