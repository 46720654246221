import { HeadingConfig } from "../styles/Heading.config";
import {
  HeadingContrast,
  HeadingSize,
  HeadingVariant,
  HeadingWeight,
} from "../types/Heading.constants";
import { THeadingHtml } from "../types/Heading.types";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";

const headingAsHtml: Record<HeadingVariant, THeadingHtml> = {
  [HeadingVariant.Hero]: `h1`,
  [HeadingVariant.Display]: `h2`,
  [HeadingVariant.Section]: `h2`,
  [HeadingVariant.Feature]: `h3`,
  [HeadingVariant.Card]: `h3`,
  [HeadingVariant.List]: `h3`,
};

const headingSize: Record<HeadingVariant, HeadingSize> = {
  [HeadingVariant.Hero]: HeadingSize.Fluid,
  [HeadingVariant.Display]: HeadingSize.Fluid,
  [HeadingVariant.Section]: HeadingSize.Fluid,
  [HeadingVariant.Feature]: HeadingSize.Fluid,
  [HeadingVariant.Card]: HeadingSize.Fluid,
  [HeadingVariant.List]: HeadingSize.Fluid,
};

const headingWeight: Record<HeadingVariant, HeadingWeight> = {
  [HeadingVariant.Hero]: HeadingWeight.Bold,
  [HeadingVariant.Display]: HeadingWeight.Bold,
  [HeadingVariant.Section]: HeadingWeight.Bold,
  [HeadingVariant.Feature]: HeadingWeight.Regular,
  [HeadingVariant.Card]: HeadingWeight.Regular,
  [HeadingVariant.List]: HeadingWeight.Bold,
};

const headingUppercase: Record<HeadingVariant, boolean> = {
  [HeadingVariant.Hero]: true,
  [HeadingVariant.Display]: true,
  [HeadingVariant.Section]: true,
  [HeadingVariant.Feature]: false,
  [HeadingVariant.Card]: false,
  [HeadingVariant.List]: false,
};

export interface HeadingData {
  title?: string;
}

export interface HeadingOptions {
  /**
   * Variants for `Heading`. You can extend the variant.
   */
  variant?: HeadingVariant;
  /**
   * Use to override default Html tag
   */
  as?: THeadingHtml;
  size?: HeadingSize;
  contrast?: HeadingContrast;
  weight?: HeadingWeight;
  isUppercase?: boolean;
}
export interface HeadingProps
  extends Omit<HTMLProps<any>, keyof HeadingData>,
    HeadingOptions,
    HeadingData {}

type HeadingParts = ForwardRefComponent<any, HeadingProps>;

/**
 * Heading - Headings are used for rendering title and headlines
 */
export const Heading: HeadingParts = forwardRef<HeadingProps, any>(
  (props, ref) => {
    const {
      variant = HeadingVariant.Section,
      size = headingSize[variant],
      as = headingAsHtml[variant],
      contrast = HeadingContrast.Higher,
      weight = headingWeight[variant],
      isUppercase = headingUppercase[variant],
      className,
      children,
      ...rest
    } = props;
    const Component = as;

    const { styles } = useCompConfig(HeadingConfig, {
      variant,
      css: {
        size,
        contrast,
        weight,
      },
    });

    return (
      <Component
        className={cx(styles.Root, { uppercase: isUppercase }, className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

Heading.displayName = `Heading`;
