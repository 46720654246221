import { CarouselVariant } from "../types/Carousel.constants";
import { createContext } from "@hybrbase/system";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useRef, useState } from "react";

export interface UseCarouselProps {
  /**
   * Variants for `Carousel`. You can extend the variant.
   */
  variant?: CarouselVariant;
  /**
   * Choose scroll axis between x and y. Remember to stack your slides horizontally or vertically using CSS to match this option.
   */
  axis?: "x" | "y";
  /**
   * Align the slides relative to the carousel viewport. Use one of the predefined alignments start, center or end. Alternatively, provide a number between 0 - 1 to align the slides, where 0.5 equals 50%.
   */
  align?: "start" | "center" | "end" | number;
  /**
   * Choose content direction between ltr and rtl. Please note that when using rtl, the content direction also has to be set to RTL, either by using the HTML dir attribute or the CSS direction property.
   */
  direction?: "ltr" | "rtl";
  /**
   * Group slides together. Drag interactions, dot navigation, and previous/next buttons are mapped to group slides into the given number.
   */
  slidesToScroll?: number;
  /**
   * Enables for scrolling the carousel with mouse and touch interactions.
   */
  draggable?: boolean;
  /**
   * Enables momentum scrolling. The speed and duration of the continued scrolling is proportional to how vigorous the drag gesture is.
   */
  dragFree?: boolean;
  /**
   * Enables infinite looping. Slides need position: relative; for this to work. Automatically falls back to false if slide content isn't enough to loop.
   */
  loop?: boolean;
  /**
   * Adjust scroll speed when triggered by any of the API methods. Higher numbers enables faster scrolling. Drag interactions are not affected because speed is then determined by the drag force.
   */
  speed?: number;
  /**
   * Set the initial scroll snap to the given number. First snap index starts at 0. Please note that this is not necessarily equal to the number of slides when used together with the slidesToScroll option.
   */
  startIndex?: number;
  /**
   * Clear leading and trailing empty space that causes excessive scrolling. Use trimSnaps to only use snap points that trigger scrolling or keepSnaps to keep them.
   */
  containScroll?: "" | "trimSnaps" | "keepSnaps";
  /**
   * Allow the carousel to skip scroll snaps if it's dragged vigorously. Note that this option will be ignored if the dragFree option is set to true.
   */
  skipSnaps?: boolean;
  /**
   * Choose a fraction representing the percentage portion of a slide that needs to be visible in order to be considered in view. For example, 0.5 equals 50%.
   */
  inViewThreshold?: number;
  /**
   * Autoplay functionality
   */
  hasAutoPlay?: boolean;
  /**
   * If set to false, autoplay will not be disabled after drag interactions, and it will restart every time after the interaction.
   */
  stopOnInteraction?: boolean;
  /**
   * Delay between transitions in milliseconds.
   */
  delay?: number;
  /**
   * When enabled, autoplay will pause when a mouse pointer enters the Embla Carousel container. If stopOnInteraction is also enabled, it will stop autoplay entirely instead of pausing it.
   */
  stopOnMouseEnter?: boolean;
}

/**
 * Carousel hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useCarousel = (props: UseCarouselProps) => {
  const {
    variant = CarouselVariant.Default,
    axis = "x",
    align = "center",
    direction = "ltr",
    slidesToScroll = 1,
    draggable = true,
    dragFree = false,
    loop = false,
    speed = 10,
    startIndex = 0,
    containScroll = "",
    skipSnaps = false,
    inViewThreshold = 0,
    hasAutoPlay = true,
    stopOnInteraction = false,
    delay = 8000,
    stopOnMouseEnter = true,
  } = props;

  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const autoplay = useRef(
    Autoplay(
      { delay, stopOnInteraction, stopOnMouseEnter },
      (emblaRoot) => emblaRoot.parentElement
    )
  );

  const [carouselRef, emblaApi] = useEmblaCarousel(
    {
      axis,
      align,
      direction,
      slidesToScroll,
      draggable,
      dragFree,
      loop,
      speed,
      startIndex,
      containScroll,
      skipSnaps,
      inViewThreshold,
    },
    hasAutoPlay ? [autoplay.current] : []
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  const onSlidePrev = useCallback(() => {
    emblaApi?.scrollPrev();
    hasAutoPlay && autoplay.current.reset();
  }, [emblaApi, hasAutoPlay]);

  const onSlideNext = useCallback(() => {
    emblaApi?.scrollNext();
    hasAutoPlay && autoplay.current.reset();
  }, [emblaApi, hasAutoPlay]);

  const onSlideTo = useCallback(
    (index) => {
      emblaApi?.scrollTo(index);
      hasAutoPlay && autoplay.current.reset();
    },
    [emblaApi, hasAutoPlay]
  );

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return {
    variant,
    carouselRef,
    onSlidePrev,
    onSlideNext,
    onSlideTo,
    prevBtnEnabled,
    nextBtnEnabled,
    selectedIndex,
    scrollSnaps,
  };
};

export type UseCarouselReturn = ReturnType<typeof useCarousel>;

const [CarouselProvider, useCarouselContext] = createContext<UseCarouselReturn>(
  {
    name: "CarouselContext",
    errorMessage:
      "useCarouselContext: `context` is undefined. Seems you forgot to wrap all Carousel's components within <Carousel />",
  }
);

export { CarouselProvider, useCarouselContext };
