import {
  AspectRatioSize,
  GridGap,
  TextContainerAlign,
  TileSpace,
} from "layout";
import { CardVariant } from "../types/Card.constants";

export const cardFactory = (variant: CardVariant) => {
  switch (variant) {
    case CardVariant.Step:
      return {
        grid: {
          gap: GridGap.Sm,
        },
        textContainer: {
          align: TextContainerAlign.Center,
        },
        aspectRatio: {
          size: AspectRatioSize.Square,
        },
      };
    default:
      return {
        grid: {
          gap: null,
        },
        aspectRatio: {
          size: AspectRatioSize.SixteenDivideByNine,
        },
        tile: {
          space: TileSpace.Md,
        },
      };
  }
};
