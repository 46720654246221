export enum VideoPlayerParts {
  Root = "Root",
  BackgroundVideo = "BackgroundVideo",
  Timeline = "Timeline",
  TimelineProgress = "TimelineProgress",
  TimelineInputRange = "TimelineInputRange",
  Controls = "Controls",
  ControlsButton = "ControlsButton",
  ControlsTime = "ControlsTime",
  Captions = "Captions",
}

export enum VideoPlayerVariant {
  Default = "Default",
}
