import { CardParts, CardVariant } from "../types/Card.constants";
import { TCardConfigProps } from "../types/Card.types";

export const CardConfig: TCardConfigProps = {
  parts: Object.values(CardParts),
  css: {
    variants: {
      [CardVariant.Default]: {
        Root: "rounded-md overflow-hidden h-full border-1 border-solid border-contrast-low p-xs",
        Header: "relative",
        Body: "min-h-xxxl pb-lg",
        ColumnOne: "flex-col-4",
        ColumnTwo: "flex-col-4",
        AspectRatio: " w-full rounded-md bg-primary",
        Label:
          "flex-content inline-block text-accent text-sm px-md py-xs rounded-md",
      },
      [CardVariant.Row]: {
        Root: "rounded-md overflow-hidden h-full border-1 border-solid border-contrast-low p-xs",
        Header: "relative h-full",
        Body: "min-h-xxl pb-lg md:min-h-xxxxl md:pb-xl",

        Grid: "sm:flex-nowrap",
        ColumnOne: "flex-col-4",
        ColumnTwo: "flex-col-4",
        AspectRatio: "h-full w-full rounded-md bg-primary",
      },
      [CardVariant.Step]: {
        Root: "px-lg pb-lg",
        ColumnOne: "order-2",
        ColumnTwo: "order-1",
        AspectRatio: "max-w-[100px] mx-auto mt-md",
        TextContainer: "sm:min-h-xxxxl",
        Number:
          "text-md font-primary w-lg h-lg border-1 flex justify-center items-center rounded-full border-solid border-contrast-low mx-auto",
      },
    },
  },
};
