import { CarouselParts, CarouselVariant } from "../types/Carousel.constants";
import { TCarouselConfigProps } from "../types/Carousel.types";

export const CarouselConfig: TCarouselConfigProps = {
  parts: Object.values(CarouselParts),
  css: {
    variants: {
      [CarouselVariant.Default]: {
        Root: "relative",
        Wrapper: "relative overflow-hidden",
        Slides: "flex space-x-md ",
        Slide: "relative w-full",
        DotButtons: "w-full flex space-x-md pl-0 justify-center items-center",
        DotButton:
          "block relative text-xs text-contrast-high w-md h-md rounded-full bg-current cursor-pointer transition-all duration-base",
      },
    },
  },
};
