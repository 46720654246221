import {
  TileParts,
  TilePosition,
  TileSpace,
  TileVariant,
} from "../types/Tile.constants";
import { TTileConfigProps } from "../types/Tile.types";

export const TileConfig: TTileConfigProps = {
  parts: Object.values(TileParts),
  css: {
    variants: {
      [TileVariant.Default]: {
        Root: "block",
      },
      [TileVariant.Outline]: {
        Root: "border-1 border-solid border-contrast-low rounded-md",
      },
    },
    props: {
      space: {
        [TileSpace.Sm]: {
          Root: "p-sm",
        },
        [TileSpace.Md]: {
          Root: "p-md sm:p-lg",
        },
        [TileSpace.Lg]: {
          Root: "p-lg sm:p-xl",
        },
        [TileSpace.Xl]: {
          Root: "p-xl",
        },
      },
      position: {
        [TilePosition.Center]: {
          Root: "flex justify-center items-center",
        },
      },
    },
  },
};
