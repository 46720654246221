import { ImageParts, ImageVariant } from "../types/Image.constants";
import { TImageConfigProps } from "../types/Image.types";

export const ImageConfig: TImageConfigProps = {
  parts: Object.values(ImageParts),
  css: {
    variants: {
      [ImageVariant.Default]: {
        Root: "",
      },
    },
  },
};
