// import {
//   useTransition,
//   UseTransitionProps,
// } from "../../../transition/src/utils/use-transition";
import { CssTransitionConfig } from "../styles/CssTransition.config";
import {
  CssTransitionState,
  CssTransitionVariant,
} from "../types/CssTransition.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";
import { uid } from "react-uid";
import {
  useTransition,
  UseTransitionProps,
} from "../../../transition/src/utils/use-transition";

export interface CssTransitionData {}

export interface CssTransitionOptions extends UseTransitionProps {
  show: boolean;
  variant?: CssTransitionVariant;
}
export interface CssTransitionProps
  extends Omit<HTMLProps<"div">, keyof UseTransitionProps>,
    CssTransitionOptions,
    CssTransitionData {}

type CssTransitionParts = ForwardRefComponent<"div", CssTransitionProps>;

export const CssTransition: CssTransitionParts = forwardRef<
  CssTransitionProps,
  "div"
>((props, ref) => {
  const {
    variant = CssTransitionVariant.FadeInTop,
    className,
    children,
    show = false,
    isEndingEqually = true,
    staggerDuration = 0.9,
    staggerDelay = 0.15,
    delay,
    ...rest
  } = props;

  const { styles } = useCompConfig(CssTransitionConfig, {
    variant,
    css: {
      state: show ? CssTransitionState.Enter : CssTransitionState.Exit,
    },
  });

  const { durationChild, delayChild, validChildren } = useTransition({
    children,
    delay,
    isEndingEqually,
    staggerDuration,
    staggerDelay,
  });

  return (
    <div
      className={cx("transition", styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {validChildren.map((child, index) => {
        return (
          <div
            key={uid(index)}
            className={cx("transition-child", styles.Child)}
            style={{
              ["--transition-stagger-duration-child" as string]: `${durationChild(
                index,
                !show
              )}s`,
              ["--transition-stagger-delay-child" as string]: `${delayChild(
                index,
                !show
              )}s`,
            }}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
});

CssTransition.displayName = `CssTransition`;
