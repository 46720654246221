import React, { useMemo } from "react";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { CardConfig } from "../styles/Card.config";
import { CardVariant } from "../types/Card.constants";
import { AspectRatio, Grid, TextContainer, Tile } from "layout";
import {
  Heading,
  HeadingVariant,
  Image,
  ImageProps,
  Text,
  TextVariant,
} from "../../../index";
import { cardFactory } from "../utils/card-factory";
import { uid } from "react-uid";
import { ThemeElement } from "@boilerplate/themes";

export interface CardData {
  image?: ImageProps;
  title?: string;
  text?: string;
  labels?: string[];
}

export interface CardOptions {
  /**
   * Variants for `Card`. You can extend the variant.
   */
  variant?: CardVariant;
  current?: number;
}
export interface CardProps
  extends Omit<HTMLProps<"div">, keyof CardData>,
    CardOptions,
    CardData {}

type CardParts = ForwardRefComponent<"div", CardProps>;

export const Card: CardParts = forwardRef<CardProps, "div">((props, ref) => {
  const {
    variant = CardVariant.Default,
    title,
    text,
    className,
    children,
    image,
    current,
    labels,
    ...rest
  } = props;

  const { styles } = useCompConfig(CardConfig, { variant });
  const factoryConfig = useMemo(() => cardFactory(variant), [variant]);

  return (
    <div className={cx(styles.Root, className)} ref={ref} {...rest}>
      <Grid gap={factoryConfig?.grid?.gap} className={styles.Grid}>
        {image?.src && (
          <Grid.Item className={styles.ColumnOne}>
            <header className={styles.Header}>
              <AspectRatio
                className={styles.AspectRatio}
                size={factoryConfig?.aspectRatio?.size}
              >
                <Image
                  layout="fill"
                  className="aspect-ratio-cover h-full"
                  {...image}
                />
              </AspectRatio>

              {styles.Label && labels && (
                <div className="flex-grid flex-gap-md absolute bottom-md right-md">
                  {labels?.map((item, index) => {
                    return (
                      <div
                        data-theme-element={ThemeElement.Default}
                        className={styles.Label}
                        key={uid(index)}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
            </header>
          </Grid.Item>
        )}
        <Grid.Item className={styles.ColumnTwo}>
          <Tile space={factoryConfig?.tile?.space} className={styles.Body}>
            <article>
              <TextContainer
                align={factoryConfig?.textContainer?.align}
                className={styles.TextContainer}
              >
                {variant === CardVariant.Step && (
                  <div className={styles.Number}>{current + 1}</div>
                )}

                <Heading variant={HeadingVariant.Card}>{title}</Heading>
                <Text variant={TextVariant.Small}>{text}</Text>
              </TextContainer>
            </article>
          </Tile>
        </Grid.Item>
      </Grid>
    </div>
  );
});

Card.displayName = `Card`;
