import * as React from "react";
import { isBrowser } from "utils";

/**
 * useSafeLayoutEffect enables us to safely call `useLayoutEffect` on the browser
 * (for SSR reasons)
 *
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser.
 *
 * @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * @see https://greensock.com/react-advanced#useIsomorphicLayoutEffectteRef
 * @see https://github.com/Andarist/use-isomorphic-layout-effect/blob/master/src/index.ts
 * @see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */
export const useSafeLayoutEffect = isBrowser
  ? React.useLayoutEffect
  : React.useEffect;
