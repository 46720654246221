// import { GridColumns, GridItemSize } from "layout";
import { HeadingVariant } from "elements";
import {
  AspectRatioVariant,
  GridGap,
  GridItemOffset,
  SectionYSpace,
  TextContainerAlign,
} from "layout";
import { TextWithVideoVariant } from "../types/TextWithVideo.constants";

export const textWithVideoFactory = (variant: TextWithVideoVariant) => {
  switch (variant) {
    case TextWithVideoVariant.WithArticle:
      return {
        heading: {
          variant: HeadingVariant.Hero,
        },
        section: {
          ySpace: SectionYSpace.None,
        },
        video: {
          aspectRatio: AspectRatioVariant.Inherit,
        },
        grid: {
          gap: GridGap.Xl,
        },
      };
    case TextWithVideoVariant.WithBanner:
      return {
        heading: {
          variant: HeadingVariant.Section,
        },
        section: {
          ySpace: SectionYSpace.None,
        },
        gridItemLayout: {
          offset: GridItemOffset.One,
        },
      };
    default:
      return {
        heading: {
          variant: HeadingVariant.Section,
        },
        textContainer: {
          align: TextContainerAlign.Center,
        },
      };
  }
};
