import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { IntroConfig } from "../styles/Intro.config";
import { IntroVariant } from "../types/Intro.constants";
import {
  Button,
  ButtonProps,
  ButtonVariant,
  Heading,
  HeadingVariant,
  IconName,
  Text,
  TextVariant,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  TextContainerAlign,
  ContainerSize,
  SectionYSpace,
} from "layout";
import { ThemeElement } from "@boilerplate/themes";

export interface IntroData extends SectionData {
  button?: ButtonProps;
}

export interface IntroOptions {
  variant?: IntroVariant;
  theme?: ThemeElement;
}

export interface IntroProps
  extends Omit<HTMLProps<"section">, keyof IntroData>,
    IntroOptions,
    IntroData {}

export const Intro = forwardRef<IntroProps, "section">((props, ref) => {
  const {
    variant = IntroVariant.Default,
    className,
    theme,
    title,
    text,
    subtitle,
    button,
    ...rest
  } = props;

  const { styles } = useCompConfig(IntroConfig, { variant });

  return (
    <Section
      theme={theme}
      ySpace={SectionYSpace.None}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Container size={ContainerSize.Sm}>
        {subtitle && (
          <TextContainer align={TextContainerAlign.Center} className="mb-sm">
            <Text variant={TextVariant.Subtitle}>{subtitle}</Text>
          </TextContainer>
        )}
        <TextContainer align={TextContainerAlign.Center}>
          <Heading variant={HeadingVariant.Section}>{title}</Heading>
          {text && <Text>{text}</Text>}

          {button && (
            <Button.Link
              variant={ButtonVariant.Outline}
              iconName={IconName.ArrowRight}
              {...button}
            />
          )}
        </TextContainer>
      </Container>
    </Section>
  );
});

Intro.displayName = `Intro`;
