import { Testimonial } from "sections";
import { getImageService, storyblokEditable } from "@hybrbase/storyblok";

const filteredItems = (items) => {
  return items.map((item) => {
    return {
      text: item.text,
      image: {
        src: getImageService(item.image?.filename),
        alt: item.image?.alt,
        title: item.image?.tile,
      },
      author: {
        name: item?.author_name,
        role: item?.author_role,
      },
    };
  });
};

export const SbTestimonial = ({ blok }) => {
  const { title, items, video, video_poster_src } = blok; // Component scheme

  return (
    <Testimonial
      title={title}
      items={filteredItems(items)}
      video={{
        src: video?.filename,
        alt: video?.alt,
        title: video?.title,
        posterSrc: video_poster_src?.filename,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
