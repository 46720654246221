import { TextWithMedia } from "sections";
import { getImageService, storyblokEditable } from "@hybrbase/storyblok";

export const SbTextWithMedia = ({ blok }) => {
  const { subtitle, title, text, button_label, button_src, image } = blok; // Component scheme
  return (
    <TextWithMedia
      subtitle={subtitle}
      text={text}
      title={title}
      image={{
        src: getImageService(image?.filename),
        alt: image?.alt,
        title: image?.title,
      }}
      button={{
        title: button_label,
        href: button_src?.cached_url,
        children: button_label,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
