import { IntroParts, IntroVariant } from "../types/Intro.constants";
import { TIntroConfigProps } from "../types/Intro.types";

export const IntroConfig: TIntroConfigProps = {
  parts: Object.values(IntroParts),
  css: {
    variants: {
      [IntroVariant.Default]: {
        Root: "pb-fluid-xxl",
      },
    },
  },
};
