export enum CardParts {
  Root = "Root",
  Header = "Header",
  Body = "Body",
  Grid = "Grid",
  ColumnOne = "ColumnOne",
  ColumnTwo = "ColumnTwo",
  TextContainer = "TextContainer",
  AspectRatio = "AspectRatio",
  Number = "Number",
  Label = "Label",
}

export enum CardVariant {
  Default = "default",
  Row = "row",
  Step = "step",
}
