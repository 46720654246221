export enum AspectRatioParts {
  Root = "Root",
  Content = "Content",
}

export enum AspectRatioVariant {
  Default = "default",
  Inherit = "inherit",
}

export enum AspectRatioLayout {
  Contain = "contain",
  Cover = "cover",
}

export enum AspectRatioSize {
  Square = "square",
  TwoDivideByOne = "two-divide-by-one",
  TwoDivideByThree = "two-divide-by-three",
  ThreeDivideByOne = "three-divide-by-one",
  ThreeDivideByTwo = "three-divide-by-two",
  FourDivideByThree = "four-divide-by-three",
  SixteenDivideByNine = "sixteen-divide-by-nine",
  SixteenDivideByTen = "sixteen-divide-by-ten",
  TwentyOneDivideByNine = "twentyOne-divide-by-nine",
}

export enum AspectRatioPosition {
  LeftTop = "left-top",
  LeftCenter = "left-center",
  LeftBottom = "left-bottom",
  RightTop = "right-top",
  RightCenter = "right-center",
  RightBottom = "right-bottom",
  CenterTop = "center-top",
  CenterCenter = "center-center",
  CenterBottom = "center-bottom",
}
