import {
  HeadingContrast,
  HeadingParts,
  HeadingSize,
  HeadingVariant,
  HeadingWeight,
} from "../types/Heading.constants";
import { THeadingConfigProps } from "../types/Heading.types";

export const HeadingConfig: THeadingConfigProps = {
  parts: Object.values(HeadingParts),
  css: {
    variants: {
      [HeadingVariant.Section]: {
        props: {
          size: {
            [HeadingSize.Fluid]: {
              Root: "text-fluid-xl",
            },
          },
        },
      },
      [HeadingVariant.Hero]: {
        props: {
          size: {
            [HeadingSize.Fluid]: {
              Root: "text-fluid-xxxl",
            },
          },
        },
      },
      [HeadingVariant.Display]: {
        props: {
          size: {
            [HeadingSize.Fluid]: {
              Root: "text-fluid-xxl",
            },
          },
        },
      },
      [HeadingVariant.Feature]: {
        props: {
          size: {
            [HeadingSize.Fluid]: {
              Root: "text-fluid-md",
            },
          },
        },
      },
      [HeadingVariant.Card]: {
        props: {
          size: {
            [HeadingSize.Fluid]: {
              Root: "text-fluid-md",
            },
          },
        },
      },
    },
    props: {
      size: {
        [HeadingSize.Fluid]: {
          Root: null,
        },
        [HeadingSize.Xxxxl]: {
          Root: "text-xxxxl sm:text-desktop-xxxxl",
        },
        [HeadingSize.Xxxl]: {
          Root: "text-xxxl sm:text-desktop-xxxl",
        },
        [HeadingSize.Xxl]: {
          Root: "text-xxl sm:text-desktop-xxl",
        },
        [HeadingSize.Xl]: {
          Root: "text-xl sm:text-desktop-xl",
        },
        [HeadingSize.Lg]: {
          Root: "text-lg sm:text-desktop-lg",
        },
        [HeadingSize.Md]: {
          Root: "text-md sm:text-desktop-md",
        },
        [HeadingSize.Base]: {
          Root: "text-base",
        },
        [HeadingSize.Sm]: {
          Root: "text-sm sm:text-desktop-sm",
        },
      },
      contrast: {
        [HeadingContrast.Higher]: {
          Root: "text-contrast-higher",
        },
        [HeadingContrast.High]: {
          Root: "text-contrast-high",
        },
        [HeadingContrast.Medium]: {
          Root: "text-contrast-medium",
        },
      },
      weight: {
        [HeadingWeight.Regular]: {
          Root: "font-regular",
        },
        [HeadingWeight.Bold]: {
          Root: "font-bold",
        },
      },
    },
  },
};
