import { Intro } from "sections";
import { storyblokEditable } from "@hybrbase/storyblok";

export const SbIntro = ({ blok }) => {
  const { title, text, cta_link_label, cta_link_src, subtitle } = blok; // Component scheme

  return (
    <Intro
      title={title}
      subtitle={subtitle}
      text={text}
      button={{
        href: cta_link_src?.cached_url,
        children: cta_link_label,
      }}
      {...storyblokEditable(blok)}
    />
  );
};
