import {
  TextWithVideoParts,
  TextWithVideoVariant,
} from "../types/TextWithVideo.constants";
import { TTextWithVideoConfigProps } from "../types/TextWithVideo.types";

export const TextWithVideoConfig: TTextWithVideoConfigProps = {
  parts: Object.values(TextWithVideoParts),
  css: {
    variants: {
      [TextWithVideoVariant.Default]: {
        Root: "flex flex-col-reverse",
        TextContainer: "mb-fluid-lg max-w-sm mx-auto",
      },
      [TextWithVideoVariant.WithBanner]: {
        Root: "pt-fluid-xxl mb-fluid-xxl flex items-end",
        GridLayout: "",
        Container: "mt-xxxl sm:mt-xxxxl",
        Tile: "pb-fluid-xl rounded-tl-lg relative",
        TileLayer: "block bg-white absolute top-0 left-full w-[2000px] h-full",
        TextContainer: "pt-fluid-xxl pl-lg md:px-xxl max-w-[900px]",
        TextContainerTwo: "pl-lg md:px-xxl max-w-[800px]",
        Text: "!mb-fluid-lg",
        VideoWrapper: "absolute top-0 left-0 h-full w-full",
        Video: "h-full w-full",
        InvertedRoundedCornor:
          "absolute bottom-0 right-full !text-white -rotate-90",
      },
      [TextWithVideoVariant.WithArticle]: {
        Root: "pt-0",
        Grid: "md:-ml-xxxxl",
        Tile: "rounded-tr-lg relative sm:pb-fluid-xxl -mr-xl md:-mr-xxl",
        TileLayer:
          "hidden sm:block bg-white absolute top-0 right-full w-[2000px] h-full",
        TextContainer: "pt-fluid-xl mx-lg md:ml-xxxl mr-md sm:mr-xl relative",
        TextContainerTwo: "mx-lg md:ml-0 sm:mr-xl",
        GridItemTwo: "md:flex-col-2",
        GridItemThree: "md:flex-col-2",
        Text: "!mb-fluid-lg",
        Video: "pt-fluid-xxl  sm:min-h-auto",
        Container: "ml-0 md:ml-auto !max-w-sm mt-fluid-xxl",
        InvertedRoundedCornor: "absolute bottom-0 left-full !text-white",
      },
    },
  },
};
