import { GridConfig } from "../styles/Grid.config";
import { GridItemOffset, GridItemSize } from "../types/GridItem.constants";
import { useGridContext } from "../utils/use-grid";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import React from "react";

/**
 * GridItem
 */
export interface GridItemProps extends HTMLProps<"div"> {
  size?: GridItemSize;
  offset?: GridItemOffset;
}

export const GridItem = forwardRef<GridItemProps, "div">(
  ({ className, size, offset, children, ...rest }, ref) => {
    const { variant } = useGridContext();
    const { styles } = useCompConfig(GridConfig, {
      variant,
      css: { size, offset },
    });

    return (
      <div className={cx(styles.Item, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);
