import { CarouselConfig } from "../styles/Carousel.config";
import { CarouselVariant } from "../types/Carousel.constants";
import {
  CarouselProvider,
  useCarousel,
  UseCarouselProps,
} from "../utils/use-carousel";
import { CarouselDotButton } from "./CarouselDotButton";
import { CarouselSlide } from "./CarouselSlide";
import {
  getValidChildren,
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import React from "react";
import { uid } from "react-uid";

export interface CarouselData {}

export interface CarouselOptions extends UseCarouselProps {}
export interface CarouselProps
  extends Omit<HTMLProps<"div">, keyof CarouselOptions>,
    CarouselOptions,
    CarouselData {}

type CarouselParts = ForwardRefComponent<"div", CarouselProps> & {
  Slide?: typeof CarouselSlide;
};

export const Carousel: CarouselParts = forwardRef<CarouselProps, "div">(
  (props, ref) => {
    const {
      variant = CarouselVariant.Default,
      className,
      children,
      ...rest
    } = props;

    const { styles } = useCompConfig(CarouselConfig, { variant });
    const { ...ctx } = useCarousel({ ...rest });
    const context = React.useMemo(() => ctx, [ctx]);

    return (
      <CarouselProvider value={context}>
        <div className={cx(styles.Root, className)} ref={ref} {...rest}>
          <div className={styles.Wrapper} ref={context.carouselRef}>
            <ul className={styles.Slides}>
              {children &&
                getValidChildren(children).map((child, index) => {
                  if (child.type === CarouselSlide) {
                    return (
                      <li className="relative min-w-full" key={uid(index)}>
                        {child}
                      </li>
                    );
                  }
                })}
            </ul>
          </div>

          <ul className={styles.DotButtons}>
            {context.scrollSnaps.map((_, index) => (
              <li key={uid(index)}>
                <CarouselDotButton
                  isSelected={index === context.selectedIndex}
                  onClick={() => context.onSlideTo(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      </CarouselProvider>
    );
  }
);

Carousel.Slide = CarouselSlide;

Carousel.displayName = `Carousel`;
Carousel.Slide.displayName = `Carousel.Slide`;
