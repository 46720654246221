import { storyblokEditable } from "@hybrbase/storyblok";
import { getRichText } from "@hybrbase/storyblok";
import { TextContainer } from "layout";
import React from "react";

export const SbRichText = ({ blok }) => {
  const { text } = blok; // Component scheme
  return (
    <TextContainer {...storyblokEditable(blok)}>
      {getRichText(text)}
    </TextContainer>
  );
};
