import React from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { AppFooterConfig } from "../styles/AppFooter.config";
import { AppFooterVariant } from "../types/AppFooter.constants";
import { Icon, IconName, IconSize, Link, LinkProps } from "elements";
import { Container } from "layout";
import { ThemeElement } from "@boilerplate/themes";
import { uid } from "react-uid";
import { useRouter } from "next/router";

export interface AppFooterData {
  links?: LinkProps[];
  socialLinks?: LinkProps[];
  colophonLinks?: LinkProps[];
}

export interface AppFooterOptions {
  variant?: AppFooterVariant;
  theme?: ThemeElement;
}

export interface AppFooterProps
  extends Omit<HTMLProps<"section">, keyof AppFooterData>,
    AppFooterOptions,
    AppFooterData {}

export const AppFooter = forwardRef<AppFooterProps, "section">((props, ref) => {
  const {
    variant = AppFooterVariant.Default,
    className,
    theme = ThemeElement.Default,
    title,
    text,
    links,
    socialLinks,
    colophonLinks,
    ...rest
  } = props;

  const { styles } = useCompConfig(AppFooterConfig, { variant });
  const { asPath } = useRouter();

  return (
    <footer
      data-theme-element={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      <Container className="pb-lg border-b-1 border-solid border-contrast-low">
        <nav
          className={styles.Nav}
          role="navigation"
          aria-labelledby="app-footer-nav"
        >
          <span id="app-header-nav" className="sr-only">
            app-footer navigation
          </span>

          <Link title="home" href="/" className={styles.Brand}>
            <span className="sr-only">home</span>
            <Icon name={IconName.ONECYCLE} size={IconSize.Auto} />
          </Link>
          <ul className="flex flex-wrap justify-center mb-0 sm:mt-0 items-center pl-0 space-x-md">
            {links?.map((item, index) => (
              <li key={uid(index)}>
                <Link
                  className={cx(
                    " text-primary hover:text-accent active:text-accent",
                    {
                      ["!text-accent pointer-events-none"]:
                        `/${item?.href}/` === asPath,
                    }
                  )}
                  href={item?.href}
                  title={item?.children}
                >
                  {item?.children}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </Container>
      <Container className="py-md">
        <nav className="flex flex-col-reverse sm:flex-row">
          <span className="text-sm flex-1 text-center sm:text-left">
            ©2022 ONECYCLE - Made by{" "}
            <Link
              className="text-primary"
              isOpenNewTab
              href="https://hybrbase.com/"
            >
              HYBRBASE
            </Link>
          </span>
          <ul className="flex pl-0 mb-sm md:mb-0 justify-center space-x-xs flex-1">
            {colophonLinks?.map((item, index) => {
              const isLastIndex = index === colophonLinks.length - 1;

              return (
                <li key={uid(index)}>
                  <Link
                    className="text-sm"
                    href={item?.href}
                    title={item?.children}
                  >
                    {item?.children}
                  </Link>
                  {!isLastIndex && (
                    <span
                      className="text-contrast-low ml-xs"
                      aria-hidden="true"
                    >
                      /
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
          <ul className="flex mb-sm md:mb-0 space-x-xs flex-1 justify-center pl-0 sm:justify-end">
            {socialLinks?.map((item, index) => {
              const isLastIndex = index === socialLinks.length - 1;

              return (
                <li key={uid(index)}>
                  <Link
                    className="text-sm"
                    href={item?.href}
                    title={item?.children}
                    isOpenNewTab
                  >
                    <Icon
                      className={cx({ "mr-xs": item?.children })}
                      name={item?.icon}
                      size={IconSize.Md}
                    />

                    {item?.children}
                    {!isLastIndex && (
                      <span
                        className="text-contrast-low ml-xs"
                        aria-hidden="true"
                      >
                        /
                      </span>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </Container>
    </footer>
  );
});

AppFooter.displayName = `AppFooter`;
