import React, { useMemo } from "react";
import { HTMLProps, cx, forwardRef, useCompConfig } from "@hybrbase/system";
import { TextWithMediaConfig } from "../styles/TextWithMedia.config";
import { TextWithMediaVariant } from "../types/TextWithMedia.constants";
import {
  Heading,
  HeadingVariant,
  Text,
  TextVariant,
  Image,
  Button,
  ButtonProps,
  ImageProps,
  IconName,
} from "elements";
import {
  Section,
  SectionData,
  Container,
  TextContainer,
  Grid,
  AspectRatio,
  AspectRatioSize,
} from "layout";
import { ThemeElement } from "@boilerplate/themes";
import { textWithMediaFactory } from "../utils/text-with-media-factory";

export interface TextWithMediaData extends SectionData {
  button?: ButtonProps;
  image?: ImageProps;
}

export interface TextWithMediaOptions {
  variant?: TextWithMediaVariant;
  theme?: ThemeElement;
}

export interface TextWithMediaProps
  extends Omit<HTMLProps<"section">, keyof TextWithMediaData>,
    TextWithMediaOptions,
    TextWithMediaData {}

export const TextWithMedia = forwardRef<TextWithMediaProps, "section">(
  (props, ref) => {
    const {
      variant = TextWithMediaVariant.Default,
      className,
      theme,
      title,
      text,
      subtitle,
      button,
      image,
      ...rest
    } = props;

    const { styles } = useCompConfig(TextWithMediaConfig, { variant });
    const factoryConfig = useMemo(
      () => textWithMediaFactory(variant),
      [variant]
    );

    return (
      <Section
        theme={factoryConfig?.section?.theme}
        className={cx(styles.Root, className)}
        ref={ref}
        {...rest}
      >
        <Container>
          <Grid
            className="rounded-lg overflow-hidden"
            columns={factoryConfig?.grid?.columns}
          >
            <Grid.Item
              className="flex justify-center items-center sm:flex-col-4 md:flex-col-2"
              size={factoryConfig?.gridItem?.size}
            >
              <div className="pb-lg px-lg sm:px-xl lg:py-xl flex-grow  relative z-1">
                {subtitle && (
                  <TextContainer className="mb-sm">
                    <Text variant={TextVariant.Subtitle}>{subtitle}</Text>
                  </TextContainer>
                )}
                <TextContainer className="text-center md:text-left max-w-xxxxs sm:max-w-xxs mx-auto">
                  <Heading variant={HeadingVariant.Section}>{title}</Heading>
                  <Text>{text}</Text>

                  {button && (
                    <Button.Link
                      iconName={IconName.ArrowRight}
                      className="text-primary"
                      {...button}
                    />
                  )}
                </TextContainer>
              </div>
            </Grid.Item>

            <Grid.Item
              size={factoryConfig?.gridItem?.size}
              className="sm:flex-col-2 max-w-xxxs mx-auto"
            >
              {image?.src && (
                <AspectRatio
                  size={AspectRatioSize.FourDivideByThree}
                  className="rounded-md sm:h-full bg-primary"
                >
                  <Image
                    layout="fill"
                    className="aspect-ratio-cover"
                    {...image}
                  />
                </AspectRatio>
              )}
            </Grid.Item>
          </Grid>
        </Container>
      </Section>
    );
  }
);

TextWithMedia.displayName = `TextWithMedia`;
