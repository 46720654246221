export enum TextParts {
  Root = "Root",
}

export enum TextVariant {
  Body = "body",
  Leading = "leading",
  Small = "small",
  Subtitle = "subtitle",
}

export enum TextSize {
  Md = "md",
  Base = "base",
  Sm = "sm",
  Xs = "xs",
  Fluid = "fluid",
}

export enum TextWeight {
  Regular = "regular",
  Bold = "bold",
}

export enum TextContrast {
  High = "high",
  Medium = "medium",
  Low = "low",
  Lower = "lower",
}
