import { PageSeoProps } from "@hybrbase/system";
import { NextSeo } from "next-seo";
import React from "react";

export interface SeoHeadProps extends PageSeoProps {}

export default function SeoHead({
  title,
  description,
  thumbnail,
  defaultCanonicalUrl,
  languageAlternates,
  nextSeoProps,
}: SeoHeadProps) {
  return (
    <NextSeo
      title={title}
      description={description}
      languageAlternates={languageAlternates} // add multi-language
      openGraph={{
        title,
        description,
        images: thumbnail && [
          {
            // ensure that it use the same protocol to get the image src from storyblok
            // convert to thumbnail format 16:9
            url: thumbnail,
            alt: title,
          },
        ],
      }}
      canonical={defaultCanonicalUrl}
      {...nextSeoProps}
    />
  );
}
