export enum HeroParts {
  Root = "Root",
  Container = "Container",
  Heading = "Heading",
  Text = "Text",
  TextContainer = "TextContainer",
}

export enum HeroVariant {
  Default = "default",
}
