import { FeaturesParts, FeaturesVariant } from "../types/Features.constants";
import { TFeaturesConfigProps } from "../types/Features.types";

export const FeaturesConfig: TFeaturesConfigProps = {
  parts: Object.values(FeaturesParts),
  css: {
    variants: {
      [FeaturesVariant.Default]: {
        ColumnOne: "md:mx-auto max-w-xxxxs sm:max-w-xxs md:max-w-xs",
        CustomGrid: "mt-lg flex-grid-4 md:flex-grid-6",
        CustomGridItem: "flex-col-4 md:flex-col-2 ",
      },
      [FeaturesVariant.Columns]: {
        ColumnOne: "md:mx-auto max-w-xxxxs sm:max-w-xxs md:max-w-xs",
        CustomGrid: "mt-lg flex-col flex-grid-4 flex-gap-y-md",
        CustomGridItem: "md:flex-col-3 md:self-center",
      },
      [FeaturesVariant.Steps]: {
        CustomGrid: "mt-lg flex-grid-1 md:flex-grid-6",
      },
    },
  },
};
