import { AppHeaderParts, AppHeaderVariant } from "../types/AppHeader.constants";
import { TAppHeaderConfigProps } from "../types/AppHeader.types";

export const AppHeaderConfig: TAppHeaderConfigProps = {
  parts: Object.values(AppHeaderParts),
  css: {
    variants: {
      [AppHeaderVariant.Default]: {
        Root: "z-header top-xs -left-md -right-md -mx-xxxs md:mx-0 bg-transparent transition-none flex justify-start items-start",
        Container: "flex w-full",
        Nav: "flex-grow flex items-center h-[50px] md:h-[70px] bg-white rounded-lg border-1 border-solid border-contrast-low pl-md pr-xs",
        Brand:
          "relative flex-shrink-0 inline-block w-[80px] md:w-[100px] text-inherit hover:text-accent transition-all will-change-auto",
        MenuMobile:
          "fixed top-0 right-0 w-full h-screen z-overlay flex flex-col will-change-transform",
        MenuMobileInner: "flex flex-1 flex-col overflow-y-scroll",
      },
    },
  },
};
