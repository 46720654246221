export enum TileParts {
  Root = "Root",
}

export enum TileVariant {
  Default = "default",
  Outline = "outline",
}

export enum TileSpace {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}
export enum TileRounded {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export enum TilePosition {
  Center = "center",
}
