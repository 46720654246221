import { getValidChildren } from "@hybrbase/system";
import React from "react";

export interface UseTransitionProps {
  delay?: number;
  staggerDuration?: number;
  staggerDelay?: number;
  isEndingEqually?: boolean;
  children: React.ReactNode;
}

/**
 * Grid hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useTransition = (props: UseTransitionProps) => {
  const {
    delay = 0,
    children,
    isEndingEqually = true,
    staggerDuration = 0.6,
    staggerDelay = 0.15,
  } = props;

  const validChildren = getValidChildren(children);
  const count = validChildren.length;

  const { durationChild, delayChild } = React.useMemo(
    () => ({
      durationChild: (index: number, isReverse: boolean) => {
        const delayVal = delay + staggerDelay * index;
        const reverseDelayVal = delay + staggerDelay * (count - (index + 1));

        const duration = isEndingEqually
          ? delay + staggerDuration - delayVal
          : delay + staggerDuration;

        const reverseDuration = isEndingEqually
          ? delay + staggerDuration - reverseDelayVal
          : delay + staggerDuration;

        return isReverse ? reverseDuration : duration;
      },
      delayChild: (index: number, isReverse: boolean) => {
        const delayVal = delay + staggerDelay * index;
        const reverseDelayVal = delay + staggerDelay * (count - (index + 1));

        return isReverse ? reverseDelayVal : delayVal;
      },
    }),
    [isEndingEqually, staggerDuration, staggerDelay, count, delay]
  );

  return { validChildren, durationChild, delayChild };
};

export type UseTransitionReturn = ReturnType<typeof useTransition>;
