import { GridItemSize, TextContainerAlign } from "layout";
import { FeaturesVariant } from "../types/Features.constants";

export const featuresFactory = (variant: FeaturesVariant) => {
  switch (variant) {
    case FeaturesVariant.Steps:
      return {
        textContainer: {
          align: TextContainerAlign.Center,
        },
        gridItem: {
          size: GridItemSize.Two,
        },
      };

    default:
      return {
        textContainer: {
          align: TextContainerAlign.LeftCenter,
        },
      };
  }
};
