import {
  TextContrast,
  TextParts,
  TextSize,
  TextVariant,
  TextWeight,
} from "../types/Text.constants";
import { TTextConfigProps } from "../types/Text.types";

export const TextConfig: TTextConfigProps = {
  parts: Object.values(TextParts),
  css: {
    variants: {
      [TextVariant.Leading]: {
        props: {
          size: {
            [TextSize.Fluid]: {
              Root: "text-fluid-md",
            },
          },
        },
      },
      [TextVariant.Small]: {
        props: {
          size: {
            [TextSize.Fluid]: {
              Root: "text-fluid-xs",
            },
          },
        },
      },
      [TextVariant.Subtitle]: {
        Root: "uppercase",
      },
    },
    props: {
      size: {
        [TextSize.Fluid]: {
          Root: null,
        },
        [TextSize.Md]: {
          Root: "text-md sm:text-desktop-md",
        },
        [TextSize.Sm]: {
          Root: "text-sm sm:text-desktop-sm",
        },
        [TextSize.Xs]: {
          Root: "text-xs sm:text-desktop-xs",
        },
        [TextSize.Base]: {
          Root: "text-base",
        },
      },
      contrast: {
        [TextContrast.High]: {
          Root: "text-contrast-high",
        },
        [TextContrast.Medium]: {
          Root: "text-contrast-medium",
        },
        [TextContrast.Medium]: {
          Root: "text-contrast-medium",
        },
        [TextContrast.Low]: {
          Root: "text-contrast-low",
        },
        [TextContrast.Lower]: {
          Root: "text-contrast-lower",
        },
      },
      weight: {
        [TextWeight.Regular]: {
          Root: "font-regular",
        },
        [TextWeight.Bold]: {
          Root: "font-bold",
        },
      },
    },
  },
};
