import { storyblokEditable } from "@hybrbase/storyblok";
import { uid } from "react-uid";
import { Features, FeaturesVariant } from "sections";
import { SbCard } from "../elements";

const Components = {
  card: SbCard,
};

export const SbFeatures = ({ blok }) => {
  const { title, subtitle, variant, components } = blok; // Component scheme
  return (
    <Features
      variant={variant}
      title={title}
      subtitle={subtitle}
      {...storyblokEditable(blok)}
    >
      {components?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Features>
  );
};

export const SbStepFeatures = ({ blok }) => {
  const { title, subtitle, components } = blok; // Component scheme
  return (
    <Features
      variant={FeaturesVariant.Steps}
      title={title}
      subtitle={subtitle}
      {...storyblokEditable(blok)}
    >
      {components?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return (
            <FoundComponent key={uid(index)} blok={item} current={index} />
          );
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Features>
  );
};
