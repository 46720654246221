import getConfig from "next/config";
import { apiPlugin, storyblokInit, useStoryblok } from "@hybrbase/storyblok";

const { publicRuntimeConfig } = getConfig();

const { storyblokApi } = storyblokInit({
  accessToken: publicRuntimeConfig.STORYBLOK_API_TOKEN,
  apiOptions: {
    cache: { type: "memory", clear: "auto" },
  },
  use: [apiPlugin],
});

const getStoryblokVersion =
  process.env.NODE_ENV === "development" || publicRuntimeConfig.IS_STAGING
    ? "draft"
    : "published";

export { storyblokApi, getStoryblokVersion, useStoryblok };
