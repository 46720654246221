import { ThemeElement } from "@boilerplate/themes";
import { GridColumns, GridItemSize } from "layout";
import { TextWithMediaVariant } from "../types/TextWithMedia.constants";

export const textWithMediaFactory = (variant: TextWithMediaVariant) => {
  switch (variant) {
    default:
      return {
        section: {
          theme: ThemeElement.Invert,
        },
        grid: {
          columns: GridColumns.Four,
        },
        gridItem: {
          size: GridItemSize.Two,
        },
      };
  }
};
