import { createContext } from "@hybrbase/system";
import { VideoPlayerVariant } from "../index";

export interface UseVideoPlayerProps {
  variant?: VideoPlayerVariant;
}

/**
 * Video hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useVideoPlayer = (props: UseVideoPlayerProps) => {
  const { variant } = props;
  return { variant };
};

export type UseVideoPlayerReturn = ReturnType<typeof useVideoPlayer>;

const [VideoPlayerProvider, useVideoPlayerContext] =
  createContext<UseVideoPlayerReturn>({
    name: "VideoContext",
    errorMessage:
      "useVideoPlayerContext: `context` is undefined. Seems you forgot to wrap all Video's components within <Video />",
  });

export { VideoPlayerProvider, useVideoPlayerContext };
