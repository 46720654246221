import {
  TextWithMediaParts,
  TextWithMediaVariant,
} from "../types/TextWithMedia.constants";
import { TTextWithMediaConfigProps } from "../types/TextWithMedia.types";

export const TextWithMediaConfig: TTextWithMediaConfigProps = {
  parts: Object.values(TextWithMediaParts),
  css: {
    variants: {
      [TextWithMediaVariant.Default]: {
        Root: "",
      },
    },
  },
};
