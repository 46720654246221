import { HeroParts, HeroVariant } from "../types/Hero.constants";
import { THeroConfigProps } from "../types/Hero.types";

export const HeroConfig: THeroConfigProps = {
  parts: Object.values(HeroParts),
  css: {
    variants: {
      [HeroVariant.Default]: {
        Container: "flex items-end pb-fluid-lg justify-center mt-fluid-xxl",
        Heading: "mx-auto",
        TextContainer: "max-w-[350px] sm:max-w-xs text-center mx-auto",
      },
    },
  },
};
