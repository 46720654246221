export enum FeaturesParts {
  Root = "Root",
  ColumnOne = "ColumnOne",
  ColumnTwo = "ColumnTwo",
  CustomGrid = "CustomGrid",
  CustomGridItem = "CustomGridItem",
}

export enum FeaturesVariant {
  Default = "default",
  Steps = "steps",
  Columns = "columns",
}
