import {
  VideoPlayerParts,
  VideoPlayerVariant,
} from "../utils/VideoPlayer.constants";
import { TVideoConfigProps } from "../utils/VideoPlayer.types";

export const VideoConfig: TVideoConfigProps = {
  parts: Object.values(VideoPlayerParts),
  css: {
    variants: {
      [VideoPlayerVariant.Default]: {
        Controls:
          "flex justify-center items-center absolute bottom-0 w-full z-1 box-border",
        ControlsButton:
          "border-none cursor-pointer bg-transparent p-0 color-inherit",
        ControlsTime: "text-sm -mb-xxxs",
        Timeline: "flex items-center relative flex-grow -mb-xxxs",
        TimelineProgress: "z-1 absolute",
        TimelineInputRange:
          "absolute block appearance-none w-full bg-transparent",
      },
    },
  },
};
