export enum AppHeaderParts {
  Root = "Root",
  Container = "Container",
  Nav = "Nav",
  Brand = "Brand",
  Time = "Time",
  MenuBtn = "MenuBtn",
  MenuBtnIcon = "MenuBtnIcon",
  MenuMobile = "MenuMobile",
  MenuMobileInner = "MenuMobileInner",
  MenuMobileBackdrop = "MenuMobileBackdrop",
}

export enum AppHeaderVariant {
  Default = "default",
}
