import {
  CssTransitionParts,
  CssTransitionState,
  CssTransitionVariant,
} from "../types/CssTransition.constants";
import { TCssTransitionConfigProps } from "../types/CssTransition.types";

export const CssTransitionConfig: TCssTransitionConfigProps = {
  parts: Object.values(CssTransitionParts),
  css: {
    variants: {
      [CssTransitionVariant.Fade]: {
        Child: "transition-opacity",
        props: {
          state: {
            [CssTransitionState.Enter]: {
              Child: "opacity-100",
            },
            [CssTransitionState.Exit]: {
              Child: "opacity-0",
            },
          },
        },
      },
      [CssTransitionVariant.FadeInTop]: {
        Root: "overflow-hidden",
        Child: "transition-all will-change-transform",
        props: {
          state: {
            [CssTransitionState.Enter]: {
              Child: "opacity-100 translate-y-0",
            },
            [CssTransitionState.Exit]: {
              Child: "opacity-0 translate-y-md",
            },
          },
        },
      },
    },
    props: {
      state: {
        [CssTransitionState.Enter]: {
          Child: null,
        },
        [CssTransitionState.Exit]: {
          Child: null,
        },
      },
    },
  },
};
