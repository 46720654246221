import { CarouselConfig } from "../styles/Carousel.config";
import { useCarouselContext } from "../utils/use-carousel";
import { cx, useCompConfig, HTMLProps, forwardRef } from "@hybrbase/system";

export interface CarouselDotButtonOptions {
  isSelected?: boolean;
}
export interface CarouselDotButtonProps
  extends HTMLProps<"button">,
    CarouselDotButtonOptions {}

/**
 * CarouselDotButton
 */
export const CarouselDotButton = forwardRef<CarouselDotButtonProps, "button">(
  ({ className, isSelected, children, ...rest }, ref) => {
    const { variant } = useCarouselContext();
    const { styles } = useCompConfig(CarouselConfig, {
      variant,
    });
    return (
      <button
        type="button"
        title="dot-button"
        aria-label="dot-button"
        className={cx(
          styles.DotButton,
          { "opacity-100": isSelected, "opacity-50": !isSelected },
          className
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
