import { CarouselConfig } from "../styles/Carousel.config";
import { useCarouselContext } from "../utils/use-carousel";
import { cx, useCompConfig, HTMLProps, forwardRef } from "@hybrbase/system";

export interface CarouselSlideOptions {}
export interface CarouselSlideProps
  extends HTMLProps<"div">,
    CarouselSlideOptions {}

/**
 * CarouselSlide
 */
export const CarouselSlide = forwardRef<CarouselSlideProps, "div">(
  ({ className, children, ...rest }, ref) => {
    const { variant } = useCarouselContext();
    const { styles } = useCompConfig(CarouselConfig, {
      variant,
    });
    return (
      <div className={cx(styles.Slide, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);
