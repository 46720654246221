import React, { useEffect, useState, useRef } from "react";
import { VideoConfig } from "../styles/VideoPlayer.config";
import { VideoPlayerVariant } from "../utils/VideoPlayer.constants";
import {
  VideoPlayerProvider,
  useVideoPlayer,
  UseVideoPlayerProps,
} from "../utils/use-video-player";
import BackgroundVideo from "react-background-video-player";
import fullscreenHandler from "fullscreen-handler";
import {
  VideoPlayerControls,
  VideoPlayerControlsData,
} from "./VideoPlayerControls";
import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  IconSize,
} from "../../../index";
import { cx, useCompConfig } from "@hybrbase/system";
import noop from "no-op";
import { AspectRatio, AspectRatioLayout } from "layout";

const useSafeLayoutEffect =
  typeof document !== "undefined" ? React.useLayoutEffect : React.useEffect;

export interface VideoPlayerData extends VideoPlayerControlsData {
  src: string;
  captions?: { [key: string]: any };
}

export interface VideoPlayerOptions {
  preload?: string;
  /**
   *  play inline on iPhone. avoid triggering native video player
   */
  playsInline?: boolean;
  crossOrigin?: string;
  poster?: string;
  loop?: boolean;
  /**
   * required to be set to true for auto play on mobile in combination with 'autoPlay' option
   */
  muted?: boolean;
  autoPlay?: boolean;
  volume?: number;
  hasTogglePlayOnClick?: boolean;
  isOnPlay?: boolean;
  windowWidth?: number;
  windowHeight?: number;
  startTime?: number;
  allowKeyboardControl?: boolean;
  hasShowControlsOnLoad?: boolean;
  hasControls?: boolean;
  hasOnPlayFullscreen?: boolean;
  autoPlayDelay?: number;
  /**
   *  do not apply cover effect (e.g. disable it for specific screen resolution or aspect ratio)
   */
  hasDisableBackgroundCover?: boolean;
  controlsTimeout?: number;
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
}
export interface VideoPlayerProps
  extends VideoPlayerOptions,
    VideoPlayerData,
    UseVideoPlayerProps {
  /**
   * Variants for `Video`. You can extend the variant.
   */
  variant?: VideoPlayerVariant;
  /**
   * Use the className prop in Video to add classes
   */
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  variant = VideoPlayerVariant.Default,
  className,
  children,
  style,
  src,
  poster,
  preload,
  playsInline = true,
  crossOrigin = "anonymous",
  loop = true,
  muted = false,
  captions,
  autoPlay = false,
  volume = 1,
  hasTogglePlayOnClick = true,
  windowWidth,
  windowHeight,
  startTime = 0,
  allowKeyboardControl,
  hasShowControlsOnLoad = true,
  hasControls = false,
  autoPlayDelay = 0,
  hasDisableBackgroundCover,
  controlsTimeout = 2.5,
  onPlay = noop,
  onPause = noop,
  onEnd = noop,
  isOnPlay,
  navAriaLabel = "Video Controls",
  playLabel = "Play Video",
  pauseLabel = "Pause Video",
  captionsHideLabel = "Hide Captions",
  captionsShowLabel = "Show Captions",
  unmuteLabel = "Unmute Video",
  muteLabel = "Mute Video",
  exitFullscreenLabel = "Exit Fullscreen Mode",
  enterFullscreenLabel = "Enter Fullscreen Mode",
  playIcon = IconName.Play,
  pauseIcon = IconName.Pause,
  captionsOnIcon = IconName.CaptionsOn,
  captionsOffIcon = IconName.CaptionsOff,
  mutedIcon = IconName.Muted,
  unmutedIcon = IconName.Unmuted,
  exitFullscreenIcon = IconName.ExitFullscreen,
  enterFullscreenIcon = IconName.EnterFullscreen,
  hasOnPlayFullscreen = true,
  ...rest
}) => {
  const container = useRef(null);
  const fullScreen = useRef(null);
  const controls = useRef(null);
  const captionsContainer = useRef(null);
  const trackRef = useRef(null);
  const autoPlayTimeout = useRef(null);
  const hideControlsTimeout = useRef(null);
  const VideoRef = useRef(null);

  const [containerSize, setContainerSize] = useState({
    width: windowWidth || 0,
    height: windowHeight || 0,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(muted);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isShowingControls, setIsShowingControls] = useState(
    hasShowControlsOnLoad
  );
  const [isShowingCaptions, setIsShowingCaptions] = useState(
    captions && captions.default
  );
  const [currentCaptions, setCurrentCaptions] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  const { styles } = useCompConfig(VideoConfig, { variant });

  useSafeLayoutEffect(() => {
    fullScreen.current = fullscreenHandler(
      container.current,
      onEnterFullScreen,
      onExitFullScreen
    );
    controls.current = container.current.querySelector(
      ".video-player-controls"
    );

    if (hasControls) {
      hasShowControlsOnLoad ? setHideControlsTimeout() : hideControls();
    }

    if (autoPlay) {
      autoPlayTimeout.current = setTimeout(() => {
        play();
        clearAutoPlayTimeout();
      }, autoPlayDelay * 1000);
    }

    return () => {
      pause();
      isFullScreen && fullScreen.current.exit();

      clearAutoPlayTimeout();
      clearHideControlsTimeout();
      fullScreen.current.destroy();
      trackRef.current &&
        trackRef.current.removeEventListener("cuechange", onTrackChange);
    };
  }, []);

  useEffect(() => {
    setContainerSize({ width: windowWidth, height: windowHeight });
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (isPlaying || isOnPlay) {
      onPlay();
      hasControls && setHideControlsTimeout();
    } else {
      onPause();
      if (hasControls && progress) {
        clearHideControlsTimeout();
        showControls();
      }
    }
  }, [isPlaying, isOnPlay]);

  useEffect(() => {
    if (hasOnPlayFullscreen && isFullScreen) {
      play();
    } else {
      pause();
    }
  }, [isFullScreen, hasOnPlayFullscreen]);

  useEffect(() => {
    setCaptions(captions);
  }, [captions]);

  function showControls() {
    !isShowingControls && setIsShowingControls(true);
  }

  function hideControls() {
    isShowingControls && setIsShowingControls(false);
  }

  function play() {
    !isPlaying && VideoRef.current.play();
  }

  function pause() {
    isPlaying && VideoRef.current.pause();
  }

  function togglePlay() {
    VideoRef.current.togglePlay();
  }

  function toggleMute() {
    VideoRef.current.toggleMute();
  }

  function toggleFullscreen() {
    isFullScreen ? fullScreen.current.exit() : fullScreen.current.enter();
  }

  function toggleCaptions() {
    setIsShowingCaptions(!isShowingCaptions);
  }

  function setCaptions(captions) {
    if (!captions) return;

    const video = VideoRef.current.video;
    if (video.contains(trackRef.current)) {
      video.removeChild(trackRef.current);
      trackRef.current.removeEventListener("cuechange", onTrackChange);
    }

    const trackElement = document.createElement("track");
    trackElement.kind = captions.kind;
    trackElement.label = captions.label;
    trackElement.srclang = captions.srclang;
    trackElement.default = captions.default;
    trackElement.src = captions.src;
    trackElement.track.mode = "hidden";

    trackRef.current = trackElement;
    video.appendChild(trackElement);
    video.textTracks[0].mode = "hidden";
    trackElement.style.display = "none";

    trackRef.current.addEventListener("cuechange", onTrackChange);
  }

  function clearHideControlsTimeout() {
    hideControlsTimeout.current && clearTimeout(hideControlsTimeout.current);
  }

  function clearAutoPlayTimeout() {
    autoPlayTimeout.current && clearTimeout(autoPlayTimeout.current);
  }

  function setHideControlsTimeout() {
    clearHideControlsTimeout();
    hideControlsTimeout.current = setTimeout(() => {
      isPlaying && hideControls();
    }, controlsTimeout * 1000);
  }

  function updateTime(currentTime) {
    VideoRef.current.setCurrentTime(Number(currentTime));
  }

  function onVideoReady(duration) {
    if (captions) {
      captions.src && setCaptions(captions);
    }
    setDuration(duration);
  }

  function onTrackChange() {
    const trackList = VideoRef.current.video.textTracks;
    const textTracks = trackList && trackList.length > 0 ? trackList[0] : null;
    const cue =
      textTracks && textTracks.activeCues && textTracks.activeCues.length > 0
        ? textTracks.activeCues[0]
        : null;
    const text = cue ? cue.text : "";
    setCurrentCaptions(text);
  }

  function onEnterFullScreen() {
    setIsFullScreen(true);
  }

  function onExitFullScreen() {
    setIsFullScreen(false);
  }

  function onVideoPlay() {
    onPlay();
    setIsPlaying(true);
  }

  function onVideoPause() {
    onPause();
    setIsPlaying(false);
  }

  function onTimeUpdate(currentTime, progress, duration) {
    setCurrentTime(currentTime);
    setDuration(duration);
    setProgress(progress);
  }

  function onMute() {
    setIsMuted(true);
  }

  function onUnmute() {
    setIsMuted(false);
  }

  function onVideoEnd() {
    onEnd();
    isFullScreen && fullScreen.current.exit();
  }

  function onMouseMove() {
    if (hasControls) {
      showControls();
      isPlaying && setHideControlsTimeout();
    }
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (allowKeyboardControl) {
      const event = e.key;
      if (event === "Spacebar" || event === " ") {
        togglePlay();
      }
    }
  }

  function onControlsFocus() {
    if (hasControls) {
      showControls();
      clearHideControlsTimeout();
    }
  }

  function onControlsBlur() {
    if (hasControls) {
      clearHideControlsTimeout();
      isPlaying && setHideControlsTimeout();
    }
  }

  const { ...ctx } = useVideoPlayer(rest);
  const context = React.useMemo(() => ({ ctx, variant }), [ctx, variant]);

  const { rootStyle } = React.useMemo(() => {
    switch (variant) {
      case VideoPlayerVariant.Default:
      default:
        return {
          rootStyle: {
            "--video-player-fullscreen-bg-color": `#000`,
            "--video-player-controls-transition": `all var(--oc-transition-duraction-fast) var(--oc-transition-ease-in-out)`,
            ...style,
          } as JSX.IntrinsicElements["style"],
        };
    }
  }, [variant, style]);

  return (
    <VideoPlayerProvider value={context}>
      <AspectRatio layout={AspectRatioLayout.Cover} className="bg-primary">
        <div
          style={rootStyle}
          className={cx(
            "video-player",
            styles.Root,
            {
              "video-player--showControls": isShowingControls,
              "video-player--showCaptions": isShowingCaptions,
            },
            className
          )}
          ref={container}
          onMouseMove={onMouseMove}
          {...rest}
        >
          <BackgroundVideo
            ref={VideoRef}
            className={styles.BackgroundVideo}
            src={src}
            containerWidth={containerSize.width}
            containerHeight={containerSize.height}
            autoPlay={false}
            poster={poster}
            muted={muted}
            loop={loop}
            disableBackgroundCover={hasDisableBackgroundCover}
            preload={preload}
            playsInline={playsInline}
            volume={volume}
            startTime={startTime}
            Video={onVideoReady}
            onPlay={onVideoPlay}
            onPause={onVideoPause}
            onTimeUpdate={onTimeUpdate}
            onMute={onMute}
            onUnmute={onUnmute}
            onEnd={onVideoEnd}
            onClick={hasTogglePlayOnClick ? togglePlay : noop}
            onKeyPress={onKeyPress}
            tabIndex={allowKeyboardControl ? 0 : null}
            extraVideoElementProps={{ crossOrigin }}
          />

          {hasOnPlayFullscreen && !hasControls && (
            <Button
              variant={ButtonVariant.Reset}
              className={cx(
                "absolute left-0 top-0 flex justify-center items-center w-full h-full"
                // styles.ControlsButton
              )}
              title={isPlaying ? pauseLabel : playLabel}
              onClick={() => {
                togglePlay(), toggleFullscreen();
              }}
            >
              {!isPlaying && (
                <Icon
                  name={playIcon}
                  title={playLabel}
                  size={IconSize.Xxl}
                  className="bg-primary rounded-full !text-white p-lg relative"
                />
              )}
            </Button>
          )}

          {captions && (
            <div
              className={cx("video-player-captions", styles.Captions)}
              ref={captionsContainer}
            >
              {currentCaptions && <p>{currentCaptions}</p>}
            </div>
          )}

          {hasControls && (
            <VideoPlayerControls
              captions={Boolean(captions)}
              currentTime={Number(currentTime)}
              isPlaying={isPlaying}
              isMuted={isMuted}
              isFullScreen={isFullScreen}
              isShowingCaptions={isShowingCaptions}
              duration={duration}
              onPlayToggle={togglePlay}
              onMuteToggle={toggleMute}
              onFullscreenToggle={toggleFullscreen}
              onCaptionsToggle={toggleCaptions}
              onTimeUpdate={updateTime}
              playIcon={IconName[playIcon]}
              pauseIcon={IconName[pauseIcon]}
              mutedIcon={IconName[mutedIcon]}
              unmutedIcon={IconName[unmutedIcon]}
              exitFullscreenIcon={IconName[exitFullscreenIcon]}
              enterFullscreenIcon={IconName[enterFullscreenIcon]}
              captionsOnIcon={IconName[captionsOnIcon]}
              captionsOffIcon={IconName[captionsOffIcon]}
              onFocus={onControlsFocus}
              onBlur={onControlsBlur}
              navAriaLabel={navAriaLabel}
              playLabel={playLabel}
              pauseLabel={pauseLabel}
              captionsHideLabel={captionsHideLabel}
              captionsShowLabel={captionsShowLabel}
              unmuteLabel={unmuteLabel}
              muteLabel={muteLabel}
              exitFullscreenLabel={exitFullscreenLabel}
              enterFullscreenLabel={enterFullscreenLabel}
            />
          )}
        </div>
      </AspectRatio>
    </VideoPlayerProvider>
  );
};

VideoPlayer.displayName = `VideoPlayer`;
