import { isBrowser } from "framer-motion";
import * as React from "react";
import { HydrationMode, LazyHydrateProps, VoidFunction } from "./types";

// React currently throws a warning when using useLayoutEffect on the server.
const useIsomorphicLayoutEffect = isBrowser
  ? React.useLayoutEffect
  : React.useEffect;

export const LazyHydrate: React.FunctionComponent<LazyHydrateProps> = ({
  mode,
  children,
  noWrapper,
  ...rest
}: LazyHydrateProps) => {
  const childRef = React.useRef<HTMLDivElement>(null);

  // Always render on server
  const [hydrated, setHydrated] = React.useState(!isBrowser);

  useIsomorphicLayoutEffect(() => {
    // No SSR Content
    if (!childRef.current.hasChildNodes()) {
      setHydrated(true);
    }
  }, []);

  React.useEffect(() => {
    if (mode === HydrationMode.SSR_ONLY || hydrated) {
      return;
    }
    const rootElement = childRef.current;

    const cleanupFns: VoidFunction[] = [];

    function hydrate() {
      setHydrated(true);
    }

    if (mode === HydrationMode.WHEN_VISIBLE) {
      const element = noWrapper
        ? rootElement
        : // As root node does not have any box model, it cannot intersect.
          rootElement.firstElementChild;

      if (element && typeof IntersectionObserver !== "undefined") {
        const observerOptions = {
          rootMargin: "250px",
        };

        const io = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              hydrate();
            }
          });
        }, observerOptions);

        io.observe(element);

        cleanupFns.push(() => {
          io.disconnect();
        });
      } else {
        return hydrate();
      }
    }

    if (mode === HydrationMode.WHEN_IDLE) {
      if (typeof requestIdleCallback !== "undefined") {
        const idleCallbackId = requestIdleCallback(hydrate, { timeout: 500 });
        cleanupFns.push(() => {
          cancelIdleCallback(idleCallbackId);
        });
      } else {
        const id = setTimeout(hydrate, 2000);
        cleanupFns.push(() => {
          clearTimeout(id);
        });
      }
    }

    return () => {
      cleanupFns.forEach((fn) => fn());
    };
  }, [hydrated, mode, noWrapper]);

  if (hydrated) {
    return (
      <div ref={childRef} style={{ display: "contents" }} {...rest}>
        {children}
      </div>
    );
  }
  return (
    <div
      ref={childRef}
      style={{ display: "contents" }}
      suppressHydrationWarning
      {...rest}
      dangerouslySetInnerHTML={{ __html: "" }}
    />
  );
};
