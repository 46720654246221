import {
  TestimonialParts,
  TestimonialVariant,
} from "../types/Testimonial.constants";
import { TTestimonialConfigProps } from "../types/Testimonial.types";

export const TestimonialConfig: TTestimonialConfigProps = {
  parts: Object.values(TestimonialParts),
  css: {
    variants: {
      [TestimonialVariant.Default]: {
        Root: "-mb-xxxxs",
      },
    },
  },
};
