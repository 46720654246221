export enum CssTransitionParts {
  Root = "Root",
  Child = "Child",
}

export enum CssTransitionVariant {
  Fade = "fade",
  FadeInTop = "fade-in-top",
}

export enum CssTransitionState {
  Enter = "enter",
  Exit = "exit",
}
