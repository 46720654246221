import { TileConfig } from "../styles/Tile.config";
import { TilePosition, TileSpace, TileVariant } from "../types/Tile.constants";
import {
  HTMLProps,
  cx,
  forwardRef,
  useCompConfig,
  ForwardRefComponent,
} from "@hybrbase/system";
import { ThemeElement } from "@boilerplate/themes";
import React from "react";

export interface TileData {}

export interface TileOptions {
  /**
   * Variants for `Tile`. You can extend the variant.
   */
  variant?: TileVariant;
  theme?: ThemeElement;
  position?: TilePosition;
  space?: TileSpace;
}
export interface TileProps
  extends Omit<HTMLProps<"div">, keyof TileData>,
    TileOptions,
    TileData {}

type TileParts = ForwardRefComponent<"div", TileProps>;

export const Tile: TileParts = forwardRef<TileProps, "div">((props, ref) => {
  const {
    variant = TileVariant.Default,
    className,
    theme,
    position,
    space,
    children,
    rounded,
    ...rest
  } = props;

  const { styles } = useCompConfig(TileConfig, {
    variant,
    css: {
      position,
      space,
      rounded,
    },
  });

  return (
    <div
      data-theme-element={theme}
      className={cx(styles.Root, className)}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

Tile.displayName = `Tile`;
