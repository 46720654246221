import { getStoryblokVersion, storyblokApi } from "@/lib/storyblok/client";

type getSlugStoryProps = {
  language: string;
  slug: string;
  options?: { [key in string]?: any };
};

// change pages folder to stories
export const getSlugStory = async ({
  language,
  slug,
  options,
}: // req,
getSlugStoryProps) => {
  const [content, header, footer] = await Promise.all([
    storyblokApi
      .get(`cdn/stories/${slug}`, {
        version: getStoryblokVersion,
        language,
        ...options,
      })
      .catch((error) => {
        console.log(error);
      }),
    storyblokApi.get(`cdn/stories/settings/main-header`, {
      version: getStoryblokVersion,
      language,
    }),
    storyblokApi.get(`cdn/stories/settings/main-footer`, {
      version: getStoryblokVersion,
      language,
    }),
  ]);

  return {
    story: content ? content?.data?.story : {},
    headerStory: header?.data?.story,
    footerStory: footer?.data?.story,
  };
};
