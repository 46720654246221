import { ThemeElement } from "@boilerplate/themes";
import { storyblokEditable } from "@hybrbase/storyblok";
import { Section } from "layout";
import { uid } from "react-uid";
import { SbContainer } from "./SbContainer";

const Components = {
  container: SbContainer,
};

export const SbSection = ({ blok }) => {
  const { variant, layout } = blok; // Component scheme
  return (
    <Section
      variant={variant}
      theme={ThemeElement.Default}
      {...storyblokEditable(blok)}
    >
      {layout?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </Section>
  );
};
