import { storyblokEditable } from "@hybrbase/storyblok";
import { Heading } from "elements";

export const SbHeading = ({ blok }) => {
  const { variant, title } = blok; // Component scheme
  return (
    <Heading variant={variant} {...storyblokEditable(blok)}>
      {title}
    </Heading>
  );
};
