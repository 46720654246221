import { storyblokEditable } from "@hybrbase/storyblok";
import { TextContainer } from "layout";
import { uid } from "react-uid";
import { SbHeading } from "../elements";
import { SbRichText } from "../elements/SbRichText";

const Components = {
  heading: SbHeading,
  rich_text: SbRichText,
};

export const SbTextContainer = ({ blok }) => {
  const { variant, elements, align } = blok; // Component scheme
  return (
    <TextContainer variant={variant} align={align} {...storyblokEditable(blok)}>
      {elements?.map((item, index) => {
        if (typeof Components[item.component] !== "undefined") {
          const FoundComponent = Components[item.component];
          return <FoundComponent key={uid(index)} blok={item} />;
        } else {
          return <p>{item.component} is not yet defined.</p>;
        }
      })}
    </TextContainer>
  );
};
