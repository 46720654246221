export enum GridParts {
  Root = "Root",
  Item = "Item",
}

export enum GridVariant {
  Default = "default",
  Custom = "custom",
}

export enum GridGap {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export enum GridColumns {
  Four = "four",
  Six = "six",
  Custom = "custom",
}
