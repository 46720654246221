import { storyblokEditable } from "@hybrbase/storyblok";
import { Card } from "elements";

export const SbCard = ({ blok, current }) => {
  const { variant, image, title, text } = blok; // Component scheme
  return (
    <Card
      variant={variant}
      current={current}
      image={{ src: image?.filename, alt: image?.altname, layout: "fill" }}
      title={title}
      text={text}
      {...storyblokEditable(blok)}
    />
  );
};
