import { createContext } from "@hybrbase/system";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export interface UseVideoProps {
  hasPlayInView?: boolean;
}

/**
 * Video hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useVideo = (props: UseVideoProps) => {
  const { hasPlayInView } = props;

  const videoRef = useRef(null);
  const isInView = useInView(videoRef);

  useEffect(() => {
    if (isInView && hasPlayInView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isInView, hasPlayInView]);

  return {
    videoRef,
    isInView,
  };
};

export type UseVideoReturn = ReturnType<typeof useVideo>;

const [VideoProvider, useVideoContext] = createContext<UseVideoReturn>({
  name: "VideoContext",
  errorMessage:
    "useVideoContext: `context` is undefined. Seems you forgot to wrap all Video's components within <Video />",
});

export { VideoProvider, useVideoContext };
